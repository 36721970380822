import faker from 'faker';
// utils

// ----------------------------------------------------------------------

const PRODUCT_NAME = [
  'Phone Passport Tag',
  'Bracelet Passport',
  'Card Passport',
  'Custom Card Passport',
  'Bundle Card Deal',
  // 'Poster Passport',
  // 'Bracelet Passport',
  'Sticker Passport'
];
const AVAILABILITY = ['available', 'available', 'soon', 'soon', 'soon', 'soon'];
const IMAGE_NAME = [
  { white: 'WhiteSticker' },
  { dark: 'DarkBracelet', white: 'WhiteBracelet' },
  { dark: 'DarkCardDesignBack', light: 'LightCardDesignBack', white: 'WhiteCardDesignBack' },
  { dark: 'DarkCustomCardDesign', light: 'LightCustomCardDesign', white: 'WhiteCustomCardDesign' },
  'StackedCardDesign',
  'BikeRackDesign',
  'StickerPassport'
];
const PRICES = [20.99, 17.99, 16.99, 20.99, 64.0, 1.5];
const PRICES_SALES = [14.99, 14.99, 12.99, 16.99, 46.99, 1.0];
const PRODUCT_COLOR = [
  '#4B4B4B',
  '#C0C0C0',
  '#FFFFFF',
  '#D784DF',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];
const SALE = ['sale', 'sale', 'sale', 'sale', 'sale', 'sale'];

// ----------------------------------------------------------------------

const products = [...Array(PRODUCT_NAME.length - 1)].map((_, index) => {
  const setIndex = index;
  return {
    id: faker.datatype.uuid(),
    cover: IMAGE_NAME[index],
    name: PRODUCT_NAME[index],
    availability: AVAILABILITY[index],
    price: PRICES[index],
    priceSale: PRICES_SALES[index],
    colors:
      (setIndex === 0 && [PRODUCT_COLOR[2]]) ||
      (setIndex === 1 && [PRODUCT_COLOR[2]]) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(0, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(0, 3)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(0, 3)) ||
      (setIndex === 5 && PRODUCT_COLOR.slice(0, 1)) ||
      (setIndex === 6 && PRODUCT_COLOR.slice(0, 1)) ||
      // (setIndex === 5 && PRODUCT_COLOR.slice(0, 1)) ||
      PRODUCT_COLOR,
    status: SALE[setIndex]
  };
});

export default products;
