/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
// components
import { alpha, styled } from '@mui/material/styles';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { LockOutlined, RequestPageRounded } from '@mui/icons-material';
import { isMobile } from 'react-device-detect';
import * as _style from '../styles';
import { setFriends } from '../store/reducers/userSlice';
import { db, storage } from '../firebase';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// ----------------------------------------------------------------------

export default function Users() {
  // const showLinks = {
  //   showCall: true,
  //   showInstagram: false,
  //   showSafari: false,
  //   showEmail: true,
  //   showWhatsApp: false,
  //   showiMessage: true
  // };
  // useEffect(() => {
  //   console.log('WHAT THE FUCK IS HAPPENING');
  //   db.collection('users')
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach(async (doc) => {
  //         db.collection('users')
  //           .doc(doc.id)
  //           .collection('friends')
  //           .catch((err) => {
  //             console.log(err);
  //             alert(err);
  //           });
  //         // await db
  //         //   .collection('users')
  //         //   .doc('5DXzRmjEtvX4bvkQXq0dqJLPRC02')
  //         //   .update({ links: {  }});
  //       });
  //     });
  // }, []);

  // useEffect(() => {
  // public information:
  // - name
  // - showLinks
  // - links
  // - settings
  // - friends
  // - uid
  // - profileImage

  //   db.collection('users')
  //     .doc('0MOesBnwdzcmkQpORxL5ZByKVQF2')
  //     .get()
  //     .then((doc) => {
  //       // console.log('name: ', doc);
  //       db.collection('users')
  //         .doc(doc.id)
  //         .get()
  //         .then((doc) => {
  //           db.collection('public')
  //             .doc(doc.id)
  //             .set(
  //               {
  //                 description: doc.data().description,
  //                 name: doc.data().name,
  //                 links: doc.data().links,
  //                 showLinks: doc.data().showLinks,
  //                 uid: doc.data().uid,
  //                 profileImage: doc.data().profileImage,
  //                 stats: doc.data().stats,
  //                 settings: doc.data().settings,
  //                 email: doc.data().email
  //               },
  //               { merge: true }
  //             )
  //             .then(() => {
  //               console.log('HELLO THERE');
  //             });
  //         });
  //     });
  // }, []);

  // useEffect(() => {
  //   db.collection('users')
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach(async (doc) => {
  //         storage
  //           .child(`profile/images/${doc.id}`)
  //           .getDownloadURL()
  //           .then((url) => {
  //             db.collection('users').doc(doc.id).update({ profileImage: url });
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       });
  //     });
  // }, []);
  // useEffect(() => {
  //   db.collection('users')
  //     .get()
  //     .then((querySnapshot) => {
  //       querySnapshot.forEach(async (doc) => {
  //         await db
  //           .collection('users')
  //           .doc("5DXzRmjEtvX4bvkQXq0dqJLPRC02")
  //           .set({ links: { Venmo: null } }, { merge: true });
  //       });
  //     });
  // }, []);
  const [label, setLabel] = useState('friends');
  const handleChange = (hold) => {
    setLabel(hold);
  };
  const uid = useSelector((state) => state.reducer.firebase?.profile?.uid);
  const myName = useSelector((state) => state.reducer.firebase?.profile?.name);

  const [friends, setFriendArray] = useState([]);
  const [groups, setGroupsArray] = useState([]);
  const [requestsArray, setRequestsArray] = useState([]);
  const [pendingArray, setPendingArray] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    db.collection('friends')
      .doc(uid)
      .collection('friends')
      .orderBy('createdAt', 'desc')
      .onSnapshot((snapshot) => {
        const holdActiveFriends = [];
        const holdRequestFriends = [];
        snapshot.forEach((doc) => {
          if (doc.data().status === 'active') {
            holdActiveFriends.push({ ...doc.data(), id: doc.id });
          } else if (doc.data().status === 'pending') {
            holdRequestFriends.push({ ...doc.data(), id: doc.id });
          } else if (doc.data().status === 'request') {
            holdRequestFriends.push({ ...doc.data(), id: doc.id });
          }
        });
        dispatch(setFriends(holdActiveFriends));
        setFriendArray(holdActiveFriends);
        setPendingArray(holdRequestFriends);
      });
  }, [uid]);

  const navigate = useNavigate();

  const addFriendBack = (friend) => {
    db.collection('friends')
      .doc(uid)
      .collection('friends')
      .doc(friend.uid)
      .set(
        {
          uid: friend.uid,
          status: 'active',
          name: friend.name,
          createdAt: new Date().toISOString()
        },
        { merge: true }
      )
      .then(() => {
        db.collection('friends')
          .doc(friend.uid)
          .collection('friends')
          .doc(uid)
          .update(
            {
              status: 'active'
            },
            { merge: true }
          )
          .catch((error) => {
            alert(error);
          });
      })
      .catch((error) => {});
  };

  const getNeededInfo = () => {
    const mergedArray = [...requestsArray, ...pendingArray];
    switch (label) {
      case 'friends':
        return friends;
      case 'groups':
        return groups;
      case 'requests':
        return mergedArray;
      default:
        return friends;
    }
  };

  const getEmptyLabel = () => {
    switch (label) {
      case 'friends':
        return 'No friends added yet. Go out and connect!';
      case 'groups':
        return 'COMING SOON!';
      case 'requests':
        return 'No requests yet';
      default:
        return 'No friends added yet. Go out and connect!';
    }
  };
  const getActionText = (status) => {
    switch (status) {
      case 'request':
        return 'Add Back';
      case 'pending':
        return 'pending...';
      case 'active':
        return 'view';
      default:
        return 'view';
    }
  };
  const getAction = (status) => {
    switch (status) {
      case 'request':
        return 'Add Back';
      case 'pending':
        return 'pending...';
      case 'active':
        return 'view';
      default:
        return 'view';
    }
  };

  return (
    <Page title="Social Passport | User">
      <Container sx={{ width: '100%' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Your friends
          </Typography>
        </Stack>
        <_style._ToggleButtonGroup
          value={label}
          fullWidth
          exclusive
          size="small"
          sx={{ mb: '16px' }}
        >
          <_style._ToggleButton
            value="friends"
            fullWidth
            style={{
              backgroundImage:
                label === 'friends'
                  ? `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                      '#FBDA61',
                      0.5
                    )} 100%)`
                  : null
            }}
            onClick={() => {
              setLabel('friends');
            }}
          >
            Friends
          </_style._ToggleButton>
          <_style._ToggleButton
            style={{
              backgroundImage:
                label === 'groups'
                  ? `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                      '#FBDA61',
                      0.5
                    )} 100%)`
                  : null
            }}
            value="groups"
            onClick={() => {
              setLabel('groups');
            }}
          >
            Groups
          </_style._ToggleButton>
          <_style._ToggleButton
            style={{
              backgroundImage:
                label === 'requests'
                  ? `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                      '#FBDA61',
                      0.5
                    )} 100%)`
                  : null
            }}
            value="requests"
            onClick={() => {
              setLabel('requests');
            }}
          >
            Requests
          </_style._ToggleButton>
        </_style._ToggleButtonGroup>
        {Object.keys(getNeededInfo()).length !== 0 ? (
          <Card>
            <Scrollbar>
              <TableContainer>
                <Table>
                  <TableBody>
                    {getNeededInfo().map((friend) => {
                      const { name } = friend;
                      const { createdAt } = friend;
                      const incognitoMode = false;
                      // const isItemSelected = selected.indexOf(name) !== -1;
                      return (
                        // eslint-disable-next-line react/jsx-key
                        <div>
                          {incognitoMode ? null : (
                            <div
                              onClick={() => {
                                if (getActionText(friend.status) !== 'Add Back') {
                                  navigate(`/profile/${friend?.uid}`, {
                                    replace: true
                                  });
                                }
                              }}
                            >
                              <TableRow
                                hover
                                key={friend.uid}
                                tabIndex={-1}
                                role="checkbox"
                                // selected={isItemSelected}
                                // aria-checked={isItemSelected}
                                sx={{
                                  display: 'table',
                                  width: '100%',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="column" marginLeft="16px">
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                      {`added ${moment(createdAt).format('MM')}/${moment(
                                        createdAt
                                      ).format('DD')}/${moment(createdAt).format('YY')}`}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell width="30%">
                                  <Stack
                                    display="flex"
                                    direction="row"
                                    width="100%"
                                    justifyContent="flex-end"
                                    // alignItems="center"
                                    // spacing={2}
                                    // style={{ margin: '16px', padding: 0 }}
                                  >
                                    {isMobile || label === 'requests' ? (
                                      <Button
                                        variant="contained"
                                        sx={{
                                          backgroundImage:
                                            friend.status === 'pending'
                                              ? 'white'
                                              : `linear-gradient(135deg, ${alpha(
                                                  '#FF5ACD',
                                                  0.5
                                                )} 0%, ${alpha('#FBDA61', 0.5)} 100%)`,
                                          backgroundColor: 'transparent',
                                          color: '#000000',
                                          width: '100%'
                                        }}
                                        fullWidth="100%"
                                        disabled={friend.status === 'pending'}
                                        onClick={() => {
                                          if (label === 'requests' && friend.status === 'request') {
                                            addFriendBack(friend);
                                            setLabel('friends');
                                          }
                                          // else if (friend.status === 'view') {
                                          //   navigate(`/profile/${friend?.uid}`, {
                                          //     replace: true
                                          //   });
                                          // }
                                        }}
                                      >
                                        {getActionText(friend.status)}
                                      </Button>
                                    ) : (
                                      <>
                                        <Button
                                          variant="outlined"
                                          sx={{
                                            // backgroundImage: `linear-gradient(135deg, ${alpha(
                                            //   '#FF5ACD',
                                            //   0.5
                                            // )} 0%, ${alpha('#FBDA61', 0.5)} 100%)`,
                                            backgroundColor: 'white',
                                            borderColor: 'gray',
                                            color: 'gray',
                                            marginRight: '16px',
                                            width: '50%'
                                          }}
                                          onClick={() => {
                                            navigate(`/messages/${friend?.uid}`, {
                                              replace: true
                                            });
                                          }}
                                        >
                                          Message
                                        </Button>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            backgroundImage: `linear-gradient(135deg, ${alpha(
                                              '#FF5ACD',
                                              0.5
                                            )} 0%, ${alpha('#FBDA61', 0.5)} 100%)`,
                                            backgroundColor: 'transparent',
                                            color: '#000000',
                                            width: '50%'
                                          }}
                                          onClick={() => {
                                            navigate(`/profile/${friend?.uid}`, {
                                              replace: true
                                            });
                                          }}
                                        >
                                          View
                                        </Button>
                                      </>
                                    )}
                                  </Stack>
                                </TableCell>
                              </TableRow>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                  </TableBody>
                  {/* {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )} */}
                </Table>
              </TableContainer>
            </Scrollbar>

            {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
          </Card>
        ) : (
          <Typography>{getEmptyLabel()}</Typography>
        )}
      </Container>
    </Page>
  );
}
