import {
  Container,
  Typography,
  Stack,
  Switch,
  Button,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import Key from '@mui/icons-material/VpnKey';
import { Link as RouterLink } from 'react-router-dom';
import VpnLockIcon from '@mui/icons-material/VpnLock';
import { isMobile } from 'react-device-detect';
import { alpha } from '@mui/material/styles';
import { logout } from '../store/reducers/userSlice';
import { db, auth } from '../firebase';

function Settings() {
  const profile = useSelector((state) => state.reducer.firebase.profile);
  const [lock, setLocked] = useState(false);
  const [saved, setSaved] = useState(false);
  const [needKey, setNeedKey] = useState(false);
  const [passwordKey, setPasswordKey] = useState('');
  const [incognitoMode, setIncognitoMode] = useState(false);

  // const getPassword = () => {
  //   if (passwordKey.length > 2) {
  //     return `**${passwordKey.slice(-2)}`;
  //   }
  // };

  useEffect(() => {
    if (profile?.settings?.lockAccount) {
      setLocked(profile?.settings?.lockAccount !== undefined);
    }
    if (profile?.settings?.needKey) {
      setNeedKey(profile?.settings?.needKey !== undefined);
    }
    if (profile?.settings?.passwordKey !== undefined) {
      setPasswordKey(profile?.settings?.passwordKey);
    }
    if (profile?.settings?.incognito !== undefined) {
      setIncognitoMode(profile?.settings?.incognito);
    }
  }, [profile]);

  const timeline = useSelector((state) => state.reducer.firebase.profile.timeline);

  const dispatch = useDispatch();

  const logoutHandler = async () => {
    dispatch(logout());
    await auth.signOut();
  };
  useEffect(() => {
    if (timeline && timeline['3'] === false) {
      db.collection('users')
        .doc(profile?.uid)
        .set({ timeline: { ...timeline, 3: true } }, { merge: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const saveSettings = () => {
    console.log(needKey);
    db.collection('public')
      .doc(profile?.uid)
      .set(
        { settings: { needKey, incognito: incognitoMode, lockAccount: lock, passwordKey } },
        { merge: true }
      )
      .then(() => {
        console.log('settings were saved');
        setSaved(true);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleKeyChange = () => {
    setNeedKey(!needKey);
  };
  const handleLockChange = () => {
    setLocked(!lock);
  };
  const handleIncognito = () => {
    setIncognitoMode(!incognitoMode);
  };
  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ marginBottom: '36px' }}>
        Settings
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        alignContent="center"
        width={isMobile ? '100%' : '75%'}
        justifyContent="space-between"
        style={{ marginBottom: '16px' }}
      >
        <Stack direction="row" alignItems="center">
          <Key style={{ width: 25, height: 25 }} />
          <Stack direction="column" marginLeft="16px">
            <Typography variant="subtitle2">Add password protection</Typography>
            <Typography variant="body2">
              When you share your profile, the receiver will need to input a 4-digit code you
              provide
            </Typography>
          </Stack>
        </Stack>
        <Switch
          checked={needKey}
          onChange={handleKeyChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
      {needKey ? (
        <Stack
          display="flex"
          direction="row"
          alignItems="center"
          width={isMobile ? '100%' : '75%'}
          style={{ marginBottom: '16px', paddingLeft: '41px' }}
        >
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            fullWidth="100%"
            defaultValue={passwordKey}
            value={passwordKey}
            error={passwordKey.length !== 4 || passwordKey.match(/^[0-9]+$/) === null}
            helperText={
              passwordKey.length !== 4 && passwordKey.match(/^[0-9]+$/) === null
                ? 'Password need to be 4 numeric digits'
                : null
            }
            onChange={(text) => {
              setPasswordKey(text.target.value);
            }}
          />
        </Stack>
      ) : null}
      <Stack
        direction="row"
        alignItems="center"
        alignContent="center"
        width={isMobile ? '100%' : '75%'}
        justifyContent="space-between"
        style={{ marginBottom: '16px' }}
      >
        <Stack direction="row" alignItems="center">
          <LockIcon style={{ width: 25, height: 25 }} />
          <Stack direction="column" marginLeft="16px">
            <Typography variant="subtitle2">Lock account</Typography>
            <Typography variant="body2">
              Lock your account so no one can access your details
            </Typography>
          </Stack>
        </Stack>
        <Switch
          checked={lock}
          onChange={handleLockChange}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        alignContent="center"
        width={isMobile ? '100%' : '75%'}
        justifyContent="space-between"
        style={{ marginBottom: '36px' }}
      >
        <Stack direction="row" alignItems="center">
          <VpnLockIcon style={{ width: 25, height: 25 }} />
          <Stack direction="column" marginLeft="16px">
            <Typography variant="subtitle2">Incognito mode</Typography>
            <Typography variant="body2">Don't allow other students to search you</Typography>
          </Stack>
        </Stack>
        <Switch
          checked={incognitoMode}
          onChange={handleIncognito}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </Stack>
      <Button
        variant="outlined"
        onClick={() => {
          if (needKey) {
            if (passwordKey.length === 4 && passwordKey.match(/^[0-9]+$/) !== null) {
              saveSettings();
            }
          } else {
            saveSettings();
          }
        }}
        sx={{ marginRight: '8px' }}
      >
        Save
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
          window.open(`sms:6096356646`);
        }}
        sx={{ marginRight: '8px' }}
      >
        contact support
      </Button>
      <Button
        variant="outlined"
        component={RouterLink}
        to="/login"
        onClick={() => {
          logoutHandler();
        }}
        color="error"
      >
        logout
      </Button>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={saved}
        autoHideDuration={2000}
        message="I love snacks"
        key="topcenter"
      >
        <Alert
          onClose={() => {
            setSaved(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          Settings information has saved
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Settings;
