import { Icon } from '@iconify/react';
import _ from 'lodash';
import appleFilled from '@iconify/icons-ant-design/link-outline';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
    '#FBDA61',
    0.5
  )} 100%)`
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.text.primary, 0)} 0%, ${alpha(
    theme.palette.text.primary,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function NumLinksTapped() {
  const stats = useSelector((state) => state.reducer.firebase.profile?.stats);
  const [totalLinkTaps, setTotalLinkTaps] = useState(0);

  useEffect(() => {
    const holdLinks = _.omit(stats, 'visits');
    if (Object.keys(holdLinks).length > 0) {
      setTotalLinkTaps(Object.values(holdLinks).reduce((a, b) => a + b));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats]);

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={appleFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3" color="text.primary">
        {totalLinkTaps}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" sx={{ opacity: 0.72 }}>
        Links tapped
      </Typography>
    </RootStyle>
  );
}
