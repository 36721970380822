import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userSlice from './userSlice';

const userPersistConfig = {
  key: 'user',
  storage
};

const firebasePersistConfig = {
  key: 'firebase',
  storage
};

const firestorePersistConfig = {
  key: 'firestore',
  storage
};

// const sharedPersistConfig = {
//   key: 'root',
//   storage
// };

const reducer = combineReducers({
  user: persistReducer(userPersistConfig, userSlice),
  firebase: persistReducer(firebasePersistConfig, firebaseReducer),
  firestore: persistReducer(firestorePersistConfig, firestoreReducer)
});

export default reducer;

export const selectUser = (state) => state.reducer.firebase.profile;
export const selectLinks = (state) => state.reducer.firebase.profile.links;
export const selectShowLinks = (state) => state.reducer.firebase?.profile?.showLinks;
export const selectCurrentShowLinks = (state) => state.reducer.user?.currentUser?.showLinks;

// export const selectLinks = (state) => state.reducer.user.links;
