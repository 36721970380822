import faker from 'faker';
// utils
import { mockImgProfile } from '../utils/mockImages';

// ----------------------------------------------------------------------

export const data = [
  { name: 'Instagram', showName: 'showInstagram', imagename: mockImgProfile('Instagram.png') },
  { name: 'Call', showName: 'showCall', imagename: mockImgProfile('Call.png') },
  { name: 'iMessage', showName: 'showiMessage', imagename: mockImgProfile('iMessage.png') },
  { name: 'Safari', showName: 'showSafari', imagename: mockImgProfile('Safari.png') },
  { name: 'Email', showName: 'showEmail', imagename: mockImgProfile('Email.png') },
  { name: 'ContactInfo', showName: 'showContactInfo', imagename: mockImgProfile('Contact.png') },
  { name: 'Stanford', showName: 'showStanford', imagename: mockImgProfile('Stanford.png') },
  { name: 'WhatsApp', showName: 'showWhatsApp', imagename: mockImgProfile('WhatsApp.png') },
  // { name: 'Facebook', showName: 'show',imagename: 'Facebook.png' },
  // { name: 'Pinterest', showName: 'show',imagename: 'Pinterest.png' },
  // { name: 'Snapchat', showName: 'show',imagename: 'Snapchat.png' },
  // { name: 'Spotify', showName: 'show',imagename: 'Spotify.png' },
  // { name: 'Soundcloud', showName: 'show',imagename: 'Soundcloud.png' },
  // { name: 'TikTok', showName: 'show',imagename: 'TikTok.png' },
  // { name: 'Twitter', showName: 'show',imagename: 'Twitter.png' },
  { name: 'Venmo', showName: 'showVenmo', imagename: mockImgProfile('Venmo.png') }
  // { name: 'Youtube', showName: 'show',imagename: 'Youtube.png' }
];

// ----------------------------------------------------------------------\
// const linkArray = Object.keys(links);
// const activeData = data.filter(function(dataPoint) {
//   // console.log(linkArray.includes(dataPoint.name))
//   return linkArray.includes(dataPoint.name)
// })
const products = [...Array(data.length)].map((_, index) => {
  const setIndex = index;
  return {
    id: faker.datatype.uuid(),
    cover: mockImgProfile(data[setIndex].imagename),
    name: data[setIndex].name,
    showName: data[setIndex].showName
  };
});

export default products;
