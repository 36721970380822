import { Box, Typography, Avatar, Link } from '@mui/material';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-outline';
import addFile from '@iconify/icons-eva/plus-outline';
import loaderIcon from '@iconify/icons-eva/loader-outline';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { storage, db } from '../../../firebase';

const AccountStyle = styled('div')(() => ({
  flex: 1,
  width: '100%',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  display: 'flex',
  marginBottom: 10
}));

const DescriptionStyle = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

MyProfileHeader.propTypes = {
  user: PropTypes.object,
  openModal: PropTypes.func,
  open: PropTypes.bool
};

export default function MyProfileHeader(props) {
  const { user, openModal, open } = props;
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(true);
  // const { isLoaded, isEmpty } = useSelector((state) => state.reducer.firebase.profile);
  const uid = useSelector((state) => state.reducer.firebase.auth.uid);
  const profileImage = useSelector((state) => state.reducer.firebase.profile.profileImage);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    _.forEach(acceptedFiles, (file) => {
      storage
        .child(`profile/images/${uid}`)
        .put(file)
        .then(() => {
          storage
            .child(`profile/images/${uid}`)
            .getDownloadURL()
            .then((url) => {
              setLoading(false);
              setUrl(url);
              db.collection('public').doc(uid).set({ profileImage: url }, { merge: true });
            })
            .catch((err) => {
              alert(err);
            });
        })
        .catch((err) => {
          alert(err);
        });
    });
    // db.collection('users').doc(uid).update({ profileImage: })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  useEffect(() => {
    if (profileImage) {
      setLoading(false);
      setUrl(profileImage);
    }
  }, [profileImage]);

  const ProfileImage = () => {
    if (!loading) {
      // console.log("HELLO THERE")
      return (
        <div style={{ height: url ? '45px' : '25px' }}>
          {url ? (
            <Avatar
              {...getInputProps}
              src={url}
              alt=""
              style={{ width: 45, height: 45, borderRadius: 5, objectFit: 'cover' }}
            />
          ) : (
            <Icon
              {...getInputProps}
              icon={addFile}
              style={{
                backgroundColor: 'lightgray',
                borderRadius: 25,
                width: '25px',
                height: '25px',
                alignSelf: 'center',
                alignContent: 'center',
                alignItems: 'center'
              }}
            />
          )}
        </div>
      );
    }
    return (
      <Icon
        {...getInputProps}
        icon={loaderIcon}
        style={{
          backgroundColor: 'lightgray',
          borderRadius: 25,
          width: '25px',
          height: '25px',
          alignSelf: 'center',
          alignContent: 'center',
          alignItems: 'center'
        }}
      />
    );
  };

  return (
    <Box sx={{ marginBottom: '24px' }}>
      <DescriptionStyle>
        <AccountStyle>
          <div
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              display: 'flex'
            }}
          >
            <div
              style={{
                display: 'flex',
                cursor: 'pointer',
                background: 'lightgray',
                height: '45px',
                width: '45px',
                borderRadius: 40,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center'
              }}
              {...getRootProps({ className: 'dropzone' })}
            >
              <input {...getInputProps()} />
              <ProfileImage />
            </div>
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user.name ? user.name : 'Loading'}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user.email ? user.email : 'Loading'}
              </Typography>
            </Box>
          </div>
          <Icon
            icon={editFill}
            width={24}
            height={24}
            onClick={() => {
              openModal(!open);
            }}
          />
        </AccountStyle>
        <Link
          variant="body2"
          sx={{
            textDecoration: 'none',
            color: 'text.primary',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={() => {
            openModal(!open);
          }}
        >
          {user?.description ? user?.description : 'add description'}
        </Link>
      </DescriptionStyle>
    </Box>
  );
}
