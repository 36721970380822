import * as Yup from 'yup';
import { useState } from 'react';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import 'firebase/auth';
import 'firebase/firestore';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography,
  Button,
  Modal,
  Box
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckBoxRounded } from '@mui/icons-material';
import { auth } from '../../../firebase';
import { login } from '../../../store/reducers/userSlice';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '16px',
  p: 4
};

export default function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [wrongPassword, setWrongPassword] = useState('');
  const [resetSent, setResetSent] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          const { user } = userCredential;
          firebase.firestore().collection('users').doc(user.uid).set(
            {
              lastSignIn: new Date().toISOString()
            },
            { merge: true }
          );
          dispatch(
            login({
              email: user.email,
              uid: user.uid,
              name: user.displayName
            })
          );
          navigate('/dashboard/profile', { replace: true });
        })
        .catch((error) => {
          alert(error);
          const errorCode = error.code;
          const errorMessage = error.message;
          if (errorCode === 'auth/wrong-password') {
            setWrongPassword(errorMessage);
          }
        });
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Button
            variant="subtitle2"
            onClick={() => {
              setOpen(true);
            }}
          >
            Change password?
          </Button>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained">
          Login
        </LoadingButton>
        {wrongPassword !== '' ? (
          <Typography sx={{ marginTop: 2, color: 'red' }}>{wrongPassword}</Typography>
        ) : null}

        <Modal
          open={open}
          onClose={() => {
            setResetSent(false);
            setOpen(false);
          }}
        >
          <Box sx={modalStyle}>
            <Typography variant="h4" sx={{ marginBottom: '32px' }}>
              Enter email to reset password
            </Typography>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email address"
              sx={{ marginBottom: '16px' }}
              onChange={(text) => {
                setEmail(text.currentTarget.value);
              }}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            >
              Hello
            </TextField>
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                console.log(email);
                auth
                  .sendPasswordResetEmail(email)
                  .then(() => {
                    console.log('WTFFFF');
                    setResetSent(true);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Send reset email
            </Button>
            {resetSent ? (
              <div
                style={{
                  flexDirection: 'row',
                  marginTop: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Icon>
                  <CheckBoxRounded style={{ color: '#37BC64', marginRight: '8px' }} />
                </Icon>
                <Typography>Email successfully sent</Typography>
              </div>
            ) : null}
          </Box>
        </Modal>
      </Form>
    </FormikProvider>
  );
}
