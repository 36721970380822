/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
// material
import { Container, Typography, Snackbar, Alert, Button } from '@mui/material';
// import { Icon } from '@iconify/react';
// import editFill from '@iconify/icons-eva/edit-outline';
import { alpha, styled } from '@mui/material/styles';

import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import { Link, useLocation } from 'react-router-dom';
// import {
//   ForumOutlined,
//   Computer,
//   PsychologyOutlined,
//   RecordVoiceOverOutlined
// } from '@mui/icons-material';
// import { mockImgProfile } from '../utils/mockImages';
// import { isMobile } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import WelcomeModal from '../components/modals/WelcomeModal';
import TimelineWidget from '../components/_dashboard/profile/TimelineWidget';
import TimelineModal from '../components/modals/TimelineModal';
import ChangeProfileModal from '../components/ChangeProfileModal';
import { selectUser } from '../store/reducers/reducer';
import MyLinks from './MyLinks';

// components
import Page from '../components/Page';
import MyProductList from '../components/_dashboard/profile/MyProductList';
//
import PRODUCTS from '../_mocks_/profile';
import MyProfileHeader from '../components/_dashboard/profile/MyProfileHeader';
import { db } from '../firebase';
// ----------------------------------------------------------------------

export default function MyProfile({ state }) {
  const location = useLocation();
  const user = useSelector(selectUser);
  const timeline = useSelector((state) => state.reducer.firebase.profile.timeline);
  const availableTasks = _.filter(timeline, (w) => w === false).length;
  const [qrLink, setQrLink] = useState('https://socialpassport.me/login');
  // const classes = [
  //   'CS 142: from languages to information',
  //   'PSYCH 45: psychology of memory',
  //   'LING 1: intro to ling',
  //   'COMM 166: virtual people'
  // ];
  const [addedDescription, setAddedDescription] = useState(
    user?.description ? user?.description : ''
  );

  const webStyle = { borderRadius: 10, alignSelf: 'center', marginBottom: '24px' };

  const [addedName, setAddedName] = useState(user?.name);
  const updateProfile = () => {
    // if (addedDescription && addedName) {
    db.collection('users')
      .doc(user?.uid)
      .set(
        {
          description: addedDescription,
          name: addedName || user?.name,
          timeline: { ...timeline, 1: true }
        },
        { merge: true }
      );
    // }
  };

  const [openProfileInfo, setOpenProfileInfo] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);
  const [openWelcome, setOpenWelcome] = useState(location.state?.register);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  useEffect(() => {
    setQrLink(`https://socialpassport.me/profile/${user?.uid}`);
    if (timeline && timeline['4'] === false && user.stats.visits !== 0) {
      db.collection('users')
        .doc(user.uid)
        .set({ timeline: { 4: true } }, { merge: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleProfileClose = () => {
    setOpenProfileInfo(false);
  };
  const handleProfileOpen = () => {
    setOpenProfileInfo(true);
  };
  const handleWelcomeClose = () => {
    setOpenWelcome(false);
  };
  const handleWelcomeOpen = () => {
    setOpenWelcome(true);
  };
  const handleTimelineClose = () => {
    setOpenTimeline(false);
  };
  const handleTimelineOpen = () => {
    setOpenTimeline(true);
  };
  const handleSnackbarOpen = () => {
    setOpenSnackbar(true);
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page title="Social Passport | Profile">
      <Container>
        {!isMobile ? (
          <>
            <MyProfileHeader user={user} openModal={setOpenProfileInfo} open={openProfileInfo} />
            <Typography variant="h4" gutterBottom sx={{ marginBottom: '32px' }}>
              Your current links
            </Typography>
            <MyProductList products={PRODUCTS} user />
          </>
        ) : (
          <MyProfileHeader user={user} openModal={setOpenProfileInfo} open={openProfileInfo} />
        )}
        {isMobile ? (
          <div style={{ marginBottom: '24px' }}>
            <Typography variant="h4" gutterBottom sx={{ marginTop: '32px' }}>
              Frquently used actions
            </Typography>
            <Link
              to="/dashboard/links"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button
                sx={{
                  width: '100%',
                  marginTop: '16px',
                  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                    '#FBDA61',
                    0.5
                  )} 100%)`,
                  backgroundColor: 'transparent',
                  color: 'black'
                }}
                variant="contained"
              >
                Add/edit links
              </Button>
            </Link>
            <Link
              to={`/profile/${user?.uid}`}
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button
                sx={{
                  width: '100%',
                  marginTop: '16px',
                  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                    '#FBDA61',
                    0.5
                  )} 100%)`,
                  backgroundColor: 'transparent',
                  color: 'black'
                }}
                variant="contained"
              >
                Test QR code
              </Button>
            </Link>
            <Link
              to="/dashboard/products"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button
                sx={{
                  width: '100%',
                  marginTop: '16px',
                  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                    '#FBDA61',
                    0.5
                  )} 100%)`,
                  backgroundColor: 'transparent',
                  color: 'black'
                }}
                variant="contained"
              >
                Buy physical passport
              </Button>
            </Link>
          </div>
        ) : null}

        <Typography variant="h4" gutterBottom sx={{ marginTop: isMobile ? 0 : '32px' }}>
          Share your profile!
        </Typography>
        <Typography variant="body2" gutterBottom sx={{ marginBottom: '32px' }}>
          Use your QR code by having friend scan
        </Typography>
        <Link to={`/profile/${user.uid}`}>
          <QRCode
            id="qrCodeId"
            value={qrLink}
            bgColor="white"
            fgColor="black"
            level="M"
            style={webStyle}
            size={isMobile ? window.innerWidth - 36 : 200}
            imageSettings={{
              src: '/static/SPlogo.png',
              excavate: true,
              height: 200 * 0.2,
              width: 200 * 0.2
            }}
          />
        </Link>

        {/* <MyLinks /> */}
        {/* {isMobile ? (
          <div>
            <Typography variant="h4" gutterBottom sx={{ marginTop: '32px' }}>
              Frquently used actions
            </Typography>
            <Link
              to="/dashboard/links"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button
                sx={{
                  width: '100%',
                  marginTop: '16px'
                }}
                variant="contained"
              >
                Add/edit links
              </Button>
            </Link>
            <Link
              to={`/profile/${user?.uid}`}
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button sx={{ width: '100%', marginTop: '16px' }} variant="contained">
                Test QR code
              </Button>
            </Link>
            <Link
              to="/dashboard/products"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button sx={{ width: '100%', marginTop: '16px' }} variant="contained">
                Buy physical passport
              </Button>
            </Link>
          </div>
        ) : null} */}

        {/* <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center'
          }}
        >
          {classes.map((value) => (
            <Card>
              <ListItem key={value} disableGutters>
                <IconStyle>{getIcon(value)}</IconStyle>
                <ListItemText primary={value} />
              </ListItem>
            </Card>
            // <ListItem key={value} disableGutters>
            //   <IconStyle>{getIcon(value)}</IconStyle>
            //   <ListItemText primary={value} />
            // </ListItem>
          ))}
        </List> */}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackbar}
          autoHideDuration={4000}
          message="I love snacks"
          key="topcenter"
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            Step 2 of profile completion done!
          </Alert>
        </Snackbar>
        {availableTasks === 0 ? null : (
          <TimelineWidget
            open={openTimeline}
            handleTimelineClose={handleTimelineClose}
            handleTimelineOpen={handleTimelineOpen}
          />
        )}
        <WelcomeModal
          open={openWelcome}
          handleClose={handleWelcomeClose}
          handleWelcomeOpen={handleWelcomeOpen}
        />
        <TimelineModal
          open={openTimeline}
          handleClose={handleTimelineClose}
          handleProfileOpen={handleProfileOpen}
        />
        <ChangeProfileModal
          open={openProfileInfo}
          handleClose={handleProfileClose}
          addedDescription={addedDescription}
          addedName={addedName}
          setAddedDescription={setAddedDescription}
          setAddedName={setAddedName}
          updateProfile={updateProfile}
          handleSnackbarOpen={handleSnackbarOpen}
        />
      </Container>
    </Page>
  );
}

// {/* <div
//   style={{
//     marginTop: 40,
//     alignSelf: 'center',
//     justifyContent: 'center'
//   }}
// >
//   <Stack
//     direction="row"
//     justifyContent="space-between"
//     alignItems="center"
//     alignContent="center"
//     display="flex"
//   >
//     <Typography variant="h4" gutterBottom>
//       Your stanford information
//     </Typography>
//     <Link to="/dashboard/links" style={{ textDecoration: 'none', color: '#000000' }}>
//       <Icon icon={editFill} width={24} height={24} />{' '}
//     </Link>
//   </Stack>
//   <Typography variant="body2" gutterBottom sx={{ marginBottom: '30px' }}>
//     Tap on edit button to change any of this information
//   </Typography>
//   <Box>
//     {/* <StanfordImg src={mockImgProfile('Stanford.png')} /> */}
//     <TextStyle>
//       <Stack
//         direction="row"
//         spacing={1}
//         sx={{ mt: 2, textAlign: 'flex-start', alignItems: 'center' }}
//       >
//         <Typography id="modal-modal-description">Dorm:</Typography>
//         <Typography id="modal-modal-description" variant="h6">
//           Sigma Nu
//         </Typography>
//       </Stack>
//       <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
//         Classes:
//       </Typography>
//       <Grid container spacing={2}>
//         {classes.map((itemClass) => (
//           <Grid item xs={isMobile ? 12 : 4}>
//             <Paper variant="outlined" sx={{ py: 2.5, textAlign: 'center' }}>
//               <Box sx={{ mb: 0.5 }}>{getIcon(itemClass)}</Box>
//               <Typography variant="h6">{itemClass}</Typography>
//             </Paper>
//           </Grid>
//         ))}
//       </Grid>
//     </TextStyle>
//   </Box>
// </div>; */}
