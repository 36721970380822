import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  DialogActions,
  Button,
  Typography,
  CardContent
} from '@mui/material';
import PropTypes from 'prop-types';
// material
// utils
// import { Link } from 'react-router-dom';

PremiumModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

// ----------------------------------------------------------------------

function PremiumModal({ handleClose, open }) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Sign up for premium!</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none'
            }
          }}
        >
          <CardContent>
            <Typography>Coming soon with some crazy features!!!</Typography>

            {/* <Typography>Have access to detailed statistics </Typography>
            <Typography>Search and find friends without using physical passport</Typography>
            <Typography>Keep track of all your school colleagues</Typography>
            <Typography>Access to mobile app where you can create groups with one click</Typography>
            <Typography>Additional links including: LinkedIn, Snapchat, Spotify, etc</Typography>
            <Typography>Create multiple accounts (social vs. professional)</Typography> */}
          </CardContent>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row">
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default PremiumModal;
