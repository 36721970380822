/* eslint-disable react-hooks/rules-of-hooks */
// routes
import { useFirestoreConnect } from 'react-redux-firebase';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import { auth } from './firebase';
import './App.css';

// ----------------------------------------------------------------------

export default function App() {
  if (auth.currentUser) {
    useFirestoreConnect({
      collection: `public`,
      storeAs: 'users'
    });
    useFirestoreConnect({
      collection: `messages`,
      storeAs: 'messages'
    });
  }

  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
