import PropTypes from 'prop-types';
// material
import { Box, Typography, Stack, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
// import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import { mockImgProduct } from '../../../utils/mockImages';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, price, status } = product;
  const getItemCover = (name) => {
    switch (name) {
      case 'Dark Bracelet Passport':
        return 'DarkBracelet';
      case 'White Bracelet Passport':
        return 'WhiteBracelet';
      case 'Dark Social Passport Card':
        return 'WhiteCardDesignBack';
      case 'Light Social Passport Card':
        return 'WhiteCardDesignBack';
      case 'White Social Passport Card':
        return 'WhiteCardDesignBack';
      case 'Dark Custom Social Passport Card':
        return 'DarkCustomCardDesign';
      case 'Light Custom Social Passport Card':
        return 'LightCustomCardDesign';
      case 'White Custom Social Passport Card':
        return 'WhiteCustomCardDesign';
      case 'Bundle Card Deal':
        return 'StackedCardDesign';
      case 'Social Passport Poster':
        return 'BikeRackDesign';
      default:
        return null;
    }
  };

  return (
    <Card>
      <Box
        sx={{
          pt: '100%',
          position: 'sticky',
          mb: 3,
          borderWidth: 2,
          marginTop: '20px',
          marginLeft: '20px',
          marginRight: '20px'
        }}
      >
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={mockImgProduct(getItemCover(name))} />
      </Box>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}
      >
        <Stack spacing={1} sx={{ p: 0.5 }}>
          <Typography variant="subtitle2" color="text.disabled" noWrap>
            {name}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
            <Typography variant="subtitle1">{price}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
