// material
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectShowLinks } from '../../../store/reducers/reducer';
import MyLinkCards from './MyLinkCards';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function MyProductList({ ...other }) {
  const links = useSelector(selectShowLinks);

  return (
    <Grid container spacing={4} {...other}>
      {links !== undefined
        ? Object.keys(links).map((product) =>
            links[product] ? (
              <Grid key={product} item xs={3} sm={2} md={2}>
                <MyLinkCards product={product} />
              </Grid>
            ) : null
          )
        : null}
    </Grid>
  );
}
