// material
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ShopProductCard from './ProductCard';
import { selectCurrentShowLinks } from '../../../store/reducers/reducer';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function ProductList({ userInfo, ...other }) {
  const links = useSelector((state) => state.reducer.user?.currentUser?.links);
  const showLinks = useSelector(selectCurrentShowLinks);

  return (
    <Grid container spacing={4} {...other}>
      {showLinks !== undefined
        ? Object.keys(showLinks).map((product) =>
            showLinks[product] ? (
              <Grid key={product} item xs={4} sm={3} md={3}>
                <ShopProductCard product={product} links={links} user={userInfo} />
                <Typography style={{ textAlign: 'center', fontSize: 14, marginTop: '8px' }}>
                  {product.replace('show', '')}
                </Typography>
              </Grid>
            ) : null
          )
        : null}
    </Grid>
  );
}
