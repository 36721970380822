import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  DialogActions,
  Button,
  Typography,
  CardContent
} from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// material
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineDot
} from '@mui/lab';
// import Links from '@mui/icons-material/LinkOutlined';

// utils
import { Link } from 'react-router-dom';

TimelineModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleProfileOpen: PropTypes.func
};

const TIMELINES = [
  {
    title: 'Add a link',
    link: '/dashboard/links',
    type: '0'
  },
  {
    title: 'Add description or profile photo',
    type: '1'
  },
  {
    title: 'Buy a physical passport item',
    link: '/dashboard/products',
    type: '2'
  },
  {
    title: 'Check out security preferences in settings',
    link: '/dashboard/settings',
    type: '3'
  },
  {
    title: 'Share your link!',
    link: '/dashboard/',
    type: '4'
  }
];

// ----------------------------------------------------------------------

OrderItem.propTypes = {
  item: PropTypes.object,
  isLast: PropTypes.bool,
  handleProfileOpen: PropTypes.func,
  handleClose: PropTypes.func
};

function OrderItem({ item, isLast, handleProfileOpen, handleClose }) {
  const { type, title, link } = item;
  const timeline = useSelector((state) => state.reducer.firebase.profile?.timeline);

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot
          variant={timeline && timeline[type] === true ? 'filled' : 'outlined'}
          sx={{
            bgcolor: timeline && timeline[type] === true ? 'success.main' : null
          }}
        />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent
        onClick={() => {
          if (title === 'Add description or profile photo') {
            handleProfileOpen();
            handleClose();
          }
        }}
      >
        {link ? (
          <Link
            to={link}
            style={{
              textDecoration: 'none',
              color: '#000000',
              '&:hover': {
                cursor: 'pointer'
              }
            }}
          >
            <Typography variant="subtitle2">{title}</Typography>
          </Link>
        ) : (
          <Typography variant="subtitle2">{title}</Typography>
        )}

        {/* <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {fDateTime(time)}
          </Typography> */}
      </TimelineContent>
    </TimelineItem>
  );
}

function TimelineModal(props) {
  const { open, handleClose, handleProfileOpen } = props;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Profile Timeline</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none'
            }
          }}
        >
          <CardContent>
            <Timeline sx={{ padding: 0 }}>
              {TIMELINES.map((item, index) => (
                <OrderItem
                  key={item.title}
                  item={item}
                  isLast={index === TIMELINES.length - 1}
                  handleProfileOpen={handleProfileOpen}
                  handleClose={handleClose}
                />
              ))}
            </Timeline>
          </CardContent>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row">
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default TimelineModal;
