/* eslint-disable react/prop-types */
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  DialogActions,
  Button,
  CardContent
} from '@mui/material';
import PropTypes from 'prop-types';
import Profile from '@mui/icons-material/PersonOutline';
import QRCode from '@mui/icons-material/QrCodeOutlined';
import Links from '@mui/icons-material/LinkOutlined';
import Shop from '@mui/icons-material/ShoppingCartOutlined';
import Return from '@mui/icons-material/KeyboardReturnOutlined';
import Stats from '@mui/icons-material/BarChart';
import Edit from '@mui/icons-material/EditOutlined';
import College from '@mui/icons-material/SchoolOutlined';
import Settings from '@mui/icons-material/SettingsOutlined';
import Lock from '@mui/icons-material/LockOutlined';
import ModalDescriptionCard from '../ModalDescriptionCard';

// material
// utils
// import { Link } from 'react-router-dom';

// WelcomeModal.propTypes = {
//   open: PropTypes.bool,
//   handleClose: PropTypes.func
// };

// ----------------------------------------------------------------------

function WelcomeModal({ handleClose, open }) {
  const PageDescriptors = {
    Profile: [
      { name: 'Edit/add profile details (description or photo)', icon: <Profile /> },
      { name: 'Interact/edit your current links', icon: <Links /> },
      { name: 'Your sharable QR code that when scanned opens your profile', icon: <QRCode /> }
    ],
    Shop: [
      { name: 'Buy any product shown on the shop', icon: <Shop /> },
      { name: 'Return any products with any problems', icon: <Return /> }
    ],
    Stats: [{ name: 'View detailed stats of how you socialize', icon: <Stats /> }],
    EditLinks: [
      { name: 'Edit/add to your links that show up on your profile', icon: <Edit /> },
      { name: 'Add college information. (classes you are taking / dorm)', icon: <College /> }
    ],
    Settings: [
      { name: 'Manage your security preferences', icon: <Settings /> },
      { name: 'Go incognito or lock your account', icon: <Lock /> }
    ]
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle variant="h3">Welcome to Social Passport!</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none'
            }
          }}
        >
          <ModalDescriptionCard
            title="Profile"
            description={PageDescriptors.Profile}
            color="primary.lightBlue"
          />
          <ModalDescriptionCard
            title="Shop"
            description={PageDescriptors.Shop}
            color="primary.blue"
          />
          <ModalDescriptionCard
            title="Stats"
            description={PageDescriptors.Stats}
            color="primary.purple"
          />
          <ModalDescriptionCard
            title="Edit links"
            description={PageDescriptors.EditLinks}
            color="primary.pink"
          />
          <ModalDescriptionCard
            title="Settings"
            description={PageDescriptors.Settings}
            color="primary.orange"
          />
          <CardContent>
            {/* <Typography>Have access to detailed statistics </Typography>
            <Typography>Search and find friends without using physical passport</Typography>
            <Typography>Keep track of all your school colleagues</Typography>
            <Typography>Access to mobile app where you can create groups with one click</Typography>
            <Typography>Additional links including: LinkedIn, Snapchat, Spotify, etc</Typography>
            <Typography>Create multiple accounts (social vs. professional)</Typography> */}
          </CardContent>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row">
          <Button onClick={handleClose}>Close</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default WelcomeModal;
