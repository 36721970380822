import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Stack, Avatar } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
// import firebase from 'firebase';
import { premium } from '../../store/reducers/userSlice';
import { selectUser } from '../../store/reducers/reducer';
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import { storage } from '../../firebase';
//
import sidebarConfig from './SidebarConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  paddingLeft: 10,
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const uid = useSelector((state) => state.reducer.firebase.auth.uid);
  const user = useSelector(selectUser);

  const [url, setUrl] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    setUrl(user.profileImage);
  }, [user]);

  // const sendToCustomerPortal = async () => {
  //   const functionRef = firebase
  //     .functions()
  //     .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
  //   const { data } = await functionRef({
  //     returnUrl: 'https://social-passport-website.vercel.app/dashboard/profile'
  //   });
  //   window.location.assign(data.url);
  // };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to={uid ? '/' : '/'} sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>
      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="/dashboard/profile">
          <AccountStyle>
            <Avatar
              src={url}
              alt=""
              style={{ width: 45, height: 45, borderRadius: 30, objectFit: 'cover' }}
            />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.name ? user.name : 'Loading'}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary', overflow: 'hidden' }}>
                {user?.email ? user.email : 'Loading'}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_register.png"
            sx={{ width: 100 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Supercharge your connections!
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Only $1 a month!
            </Typography>
          </Box>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(premium({ openPremium: true }));
            }}
          >
            Upgrade to Pro
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  // FOR SUBSCRIPTIONS: https://firebase.google.com/codelabs/stripe-firebase-extensions#0

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default'
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
