/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types';
// material
import { Box, Dialog, Typography, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import {
  ForumOutlined,
  Computer,
  PsychologyOutlined,
  RecordVoiceOverOutlined
} from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectLinks, selectUser } from '../../../store/reducers/reducer';
import { mockImgProfile } from '../../../utils/mockImages';
import { data } from '../../../_mocks_/profile';

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute'
});
const StanfordImg = styled('img')({
  top: '20%',
  width: '100%',
  objectFit: 'cover',
  position: 'absolute',
  opacity: '5%'
});
const ModalStyle = styled('div')({
  width: '100%',
  height: '100%',
  alignSelf: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  borderRadius: 20
  // left: '5%',
  // top: '15%',
  // position: 'absolute'
});
const TextStyle = styled('div')({
  margin: '20px'
});
const IconStyle = styled('div')({
  marginRight: '10px',
  height: '24px'
});

// ----------------------------------------------------------------------

MyLinkCards.propTypes = {
  product: PropTypes.string
};

export default function MyLinkCards({ product }) {
  const links = useSelector(selectLinks);
  const user = useSelector(selectUser);
  const findLink = () => data.map((e) => e.showName).indexOf(product);
  const { name, imagename } = data[findLink()];
  const [open, setOpen] = useState(false);
  const [cardData, setCardData] = useState('');
  const phoneNumber = links?.iMessage;
  const venmoMessage = 'Powered by Social Passport! :)';
  const [emailSub, emailBody] = [
    'platon@stanford.edu',
    'Introduction',
    'Great to meet you, my name is'
  ];
  const classes = [
    'CS 142: from languages to information',
    'PSYCH 45: psychology of memory',
    'LING 1: intro to ling',
    'COMM 166: virtual people'
  ];

  const getIcon = (classes) => {
    switch (classes.split(' ')[0]) {
      case 'CS':
        return <Computer />;
      case 'LING':
        return <RecordVoiceOverOutlined />;
      case 'PSYCH':
        return <PsychologyOutlined />;
      case 'COMM':
        return <ForumOutlined />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setCardData(
      `BEGIN%3AVCARD%0AVERSION%3A3.0%0AN%3A${
        user.name
      }%0ATITLE%3A${new Date().getUTCDate()}%0AEMAIL%3BTYPE%3DINTERNET%3A${
        user.email
      }%0ATEL%3BTYPE%3DWORK%3A${user.phone}%0AEND%3AVCARD;`
    );
  }, [user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (name) => {
    if (name === 'Instagram' && links?.Instagram) {
      window.open(`http://www.instagram.com/${links.Instagram}`, '_blank');
    }
    if (name === 'iMessage' && links?.iMessage) {
      window.open(`sms:${String(phoneNumber)}`);
    }
    if (name === 'Call' && links?.Call) {
      window.open(`tel://${links?.Call}`);
    }

    if (name === 'Safari' && links?.Safari) {
      window.open(`https://www.${links?.Safari}`);
    }
    if (name === 'Email' && links?.Email) {
      window.open(`mailto:${links?.Email}?subject=${emailSub}&body=${emailBody}`);
    }

    if (name === 'WhatsApp' && links?.WhatsApp) {
      window.open(`https://wa.me/1${links?.WhatsApp}`);
    }
    if (name === 'Venmo' && links?.Venmo) {
      window.open(`https://venmo.com/${links?.Venmo}?txn=pay&note=${venmoMessage}&amount=`);
    }
    if (name === 'ContactInfo' && links?.ContactInfo) {
      window.open(`data:text/x-vcard;urlencoded,${cardData}`);
    }
    if (name === 'Stanford') {
      setOpen(true);
    }
  };

  return (
    <Box sx={{ pt: '100%', position: 'relative' }}>
      <div
        style={{ outline: 'none' }}
        role="button"
        onClick={() => {
          handleClick(name);
        }}
        tabIndex={0}
      >
        <ProductImgStyle alt={name} src={imagename} />
      </div>
      <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="xl">
        <ModalStyle>
          <Box>
            <StanfordImg src={mockImgProfile('Stanford.png')} />
            <TextStyle>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h3"
                  component="h2"
                  sx={{ marginRight: 1 }}
                >
                  Stanford
                </Typography>
                <span role="img" aria-label="tree">
                  🌲
                </span>
              </div>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Dorm: Sigma Nu
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Classes:
              </Typography>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'transparent',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center'
                }}
              >
                {classes.map((value) => (
                  <ListItem key={value} disableGutters>
                    <IconStyle>{getIcon(value)}</IconStyle>
                    <ListItemText primary={value} />
                  </ListItem>
                ))}
              </List>
            </TextStyle>
          </Box>
        </ModalStyle>
      </Dialog>
    </Box>
  );
}

// if (isMobile) {
//   window.open('instagram://user?username=platon_maza');
// } else {
// }
// if (name === 'Facebook' && links?.Facebook) {
//   // if (isMobile) {
//   //   window.open('fb://profile?id=platon.mazarakis');
//   // } else {
//   window.open('http://www.facebook.com/platon.mazarakis', '_blank');
//   // }
// }
// window.open(`sms:/open?addresses=923335251661,9231213341&body=My sms text`);

// window.open(`sms:/open?addresses=923335251661,9231213341&body=My sms text`);

// if (name === 'Spotify' && links?.Instagram) {
//   window.open(`https://open.spotify.com/user/platon777`);
// }
// if (name === 'Soundcloud' && links?.Instagram) {
//   window.open(`https://soundcloud.com/pmaz007`);
// }
// if (name === 'Venmo' && links?.Instagram) {
//   if (isMobile) {
//     window.open(`venmo://create?id=Menelaos`);
//   } else {
//     window.open(`https://account.venmo.com/pay`);
//   }
// }
// if (name === 'Twitter' && links?.Instagram) {
//   // if (isMobile) {
//   //   window.open(`twitter://user?screen_name=PMazarakis`);
//   // } else {
//   window.open(`https://twitter.com/PMazarakis`);
//   // }
// }
//   if (name === 'Snapchat' && links?.Instagram) {
//     if (isMobile) {
//       window.open(`snapchat://add/mgmazarakis`);
//     }
//   }
