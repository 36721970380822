import PropTypes from 'prop-types';
// material
import { Box, Typography, Stack } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// utils
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { shop } from '../../../store/reducers/userSlice';
import { fCurrency } from '../../../utils/formatNumber';
//
import Label from '../../Label';
import ColorPreview from '../../ColorPreview';
import { mockImgProduct } from '../../../utils/mockImages';

// ----------------------------------------------------------------------

const ProductImgStyle = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'scale-down',
  position: 'absolute'
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale, availability } = product;
  const [color, setColor] = useState('#FFFFFF');
  // const { isLoading } = useSelector((state) => state.reducer.user);
  const dispatch = useDispatch();
  // const { uid } = useSelector((state) => state.reducer.firebase.auth);
  // const timeline = useSelector((state) => state.reducer.firebase.profile.timeline);
  // const addItemToBasket = () => {
  //   db.collection('users')
  //     .doc(uid)
  //     .collection('cart')
  //     .add(
  //       {
  //         id,
  //         name,
  //         color: getProductColor(color),
  //         price,
  //         status,
  //         priceSale,
  //         createdAt: new Date().toISOString()
  //       },
  //       { merge: true }
  //     );
  //   dispatch(addToBasket(product));
  // };

  const getAvailability = () => {
    if (availability === 'soon') return 'soon';
    if (availability === 'available') return 'BUY';
    return 'closed';
  };

  const getProductPrice = (name, color) => {
    if (name === 'Phone Passport Tag' && color === '#FFFFFF') {
      window.open('https://buy.stripe.com/dR68zBe361uxgCsaEH');
    }
    if (name === 'Bracelet Passport' && color === '#4B4B4B') {
      window.open('https://buy.stripe.com/5kAeXZ4sw1uxfyo7st');
    }
    if (name === 'Bracelet Passport') {
      window.open('https://buy.stripe.com/5kAeXZ4sw1uxfyo7st');
    }
    if (name === 'Card Passport' && color === '#4B4B4B') {
      return 'price_1K6jQZHXT7nKOAiryuFkEYL2';
    }
    if (name === 'Card Passport' && color === '#C0C0C0') {
      return 'price_1K2pKXHXT7nKOAirFd3vMeuS';
    }
    if (name === 'Card Passport' && color === '#FFFFFF') {
      return 'price_1K6jQHHXT7nKOAirpGliUbEz';
    }
    if (name === 'Custom Card Passport' && color === '#4B4B4B') {
      return 'price_1K6jPTHXT7nKOAirdESnOrA5';
    }
    if (name === 'Custom Card Passport' && color === '#C0C0C0') {
      return 'price_1K6jSrHXT7nKOAirzvvWM7Wq';
    }
    if (name === 'Custom Card Passport' && color === '#FFFFFF') {
      return 'price_1K6jQwHXT7nKOAirUb92yfby';
    }
    if (name === 'Bundle Card Deal') {
      return 'price_1K6jSNHXT7nKOAirpols6zFu';
    }
    if (name === 'Poster Passport') {
      return 'price_1K6jRXHXT7nKOAirERX9KBTI';
    }
    return null;
  };

  const getProductColor = (color) => {
    switch (color) {
      case '#4B4B4B':
        return 'dark';
      case '#C0C0C0':
        return 'light';
      case '#FFFFFF':
        return 'white';
      default:
        return null;
    }
  };

  const getProductName = () => {
    if (typeof cover === 'string') {
      return cover;
    }
    return cover[getProductColor(color)];
  };

  // const sendToCheckout = () => {
  //   // const priceId = getProductPrice(name, color);
  //   // console.log(priceId);
  //   db.collection('users')
  //     .doc(uid)
  //     .set({ timeline: { ...timeline, 2: true } }, { merge: true })
  //     .then(console.log('done'))
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   db.collection('users')
  //     .doc(uid)
  //     .collection('checkout_sessions')
  //     .add({
  //       price: getProductPrice(name, color),
  //       success_url: 'http://socialpassport.me/dashboard/products',
  //       cancel_url: 'https://socialpassport.me/dashboard/products',
  //       mode: 'payment'
  //     })
  //     .then((docRef) => {
  //       docRef.onSnapshot(async (snap) => {
  //         const { error, sessionId } = snap.data();
  //         if (error) {
  //           dispatch(shop({ isLoading: false }));
  //           alert(`An error occurred: ${error.message}`);
  //         }
  //         console.log(sessionId);

  //         if (sessionId) {
  //           const stripe = await loadStripe(
  //             'pk_live_51K1eo3HXT7nKOAirVnMrpr1FakNWYRpm8aG3Y74ya5CekBKKIrd7IsHj14FsYM5CDyUwGAfvlpowVzcTIl2OV9bd00nuTSDzNo'
  //           );
  //           await stripe.redirectToCheckout({ sessionId }).then(() => {
  //             console.log('HELLO');
  //             dispatch(shop({ isLoading: false }));
  //           });
  //         }
  //       });
  //     })
  //     .catch((error) => {
  //       dispatch(shop({ isLoading: false }));
  //       alert(error);
  //     });
  // };

  return (
    <Box>
      {' '}
      <Box
        sx={{
          pt: '100%',
          position: 'sticky',
          mb: 3,
          borderWidth: 2
        }}
      >
        {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase'
            }}
          >
            {status}
          </Label>
        )}
        <ProductImgStyle alt={name} src={mockImgProduct(getProductName())} />
      </Box>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        // style={{ marginLeft: '20px', marginRight: '20px', marginBottom: '20px' }}
      >
        <Stack spacing={1} sx={{ p: 0.5 }}>
          <Typography variant="subtitle2" color="text.disabled" noWrap>
            {name}
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
            <ColorPreview setColor={setColor} colors={colors} />
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  textDecoration: 'line-through'
                }}
              >
                {price && fCurrency(price)}
              </Typography>
              &nbsp;
              {fCurrency(priceSale)}
            </Typography>
          </Stack>
        </Stack>
        <div style={{ flexDirection: 'row' }}>
          <LoadingButton
            sx={{
              backgroundImage:
                availability === 'available'
                  ? `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                      '#FBDA61',
                      0.5
                    )} 100%)`
                  : 'transparent',
              backgroundColor: 'transparent',
              color: '#000000'
            }}
            onClick={() => {
              dispatch(shop({ isLoading: true }));
              getProductPrice(name, color);
            }}
            disabled={availability !== 'available'}
            variant={availability === 'soon' ? 'text' : 'contained'}
          >
            {getAvailability()}
          </LoadingButton>
        </div>
      </Stack>
    </Box>
  );
}
