import { Link as RouterLink, Outlet } from 'react-router-dom';
// material
// components
import { Container, Typography, Stack, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import AccountPopover from './dashboard/AccountPopover';

import Logo from '../components/Logo';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ProfileLayout() {
  return (
    <>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignContent: 'center',
          paddingTop: 16,
          marginBottom: isMobile ? 0 : 20,
          width: '100vw',
          flex: 1
        }}
      >
        <RouterLink to="/Login">
          <Logo />
        </RouterLink>
        <Typography variant="body" sx={{ color: 'gray' }}>
          Social Passport
        </Typography>
        {/* <div style={{ marginLeft: 16, marginBottom: 0 }}>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Connect with just a tap
          </Typography>
        </div> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover />
  <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </Container>
      <Outlet />
    </>
  );
}
