/* eslint-disable react/jsx-key */
import react, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  Alert,
  Button,
  TextField,
  Input,
  IconButton,
  Box,
  Avatar,
  Divider,
  InputBase,
  Card
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/reducers/reducer';

import { db } from '../firebase';

const SearchUsers = () => {
  const user = useSelector(selectUser);
  const uid = useSelector((state) => state.reducer.firebase?.profile?.uid);
  const [searched, setSearched] = useState([]);
  const [text, setText] = useState('');

  const handleCapitalizeText = (text) => {
    if (text) {
      const words = text.split(' ').filter((i) => i);
      for (let i = 0; i < words.length; i += 1) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(' ');
    }
    return '';
  };
  useEffect(() => {
    const searchText = handleCapitalizeText(text);
    const friends = [];
    db.collection('public')
      .where('name', '>=', searchText)
      .where('name', '<=', `${searchText}~`)
      .get()
      .then((querySnapshot) => {
        const friends = [];
        querySnapshot.forEach((doc) => {
          friends.push(doc.data());
        });
        setSearched(friends);
      });
  }, [text]);
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Search to find friends!
        </Typography>
      </Stack>
      <Card style={{ borderRadius: '5px', padding: '8px', marginBottom: '16px' }}>
        <InputBase
          size="small"
          placeholder="Type a message"
          style={{ width: '100%', paddingLeft: '8px' }}
          onChange={(event) => {
            setText(event.target.value);
          }}
          value={text}
        />
      </Card>

      {searched.length !== 0 ? (
        searched?.map((friend) => (
          <div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                flex: 1,
                paddingTop: '8px',
                paddingBottom: '8px',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
                <Avatar
                  src={friend.profileImage}
                  alt=""
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 5,
                    objectFit: 'cover',
                    marginRight: '8px'
                  }}
                />
                <div style={{ width: '100%', flex: 1 }}>
                  <Typography style={{ paddingTop: '8px' }}>{friend.name}</Typography>
                  <Typography style={{ paddingBottom: '8px', fontSize: 10, fontWeight: 'lighter' }}>
                    {friend.description}
                  </Typography>
                </div>
              </div>
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                    '#FBDA61',
                    0.5
                  )} 100%)`,
                  backgroundColor: 'white',
                  color: '#000000'
                }}
                onClick={() => {
                  db.collection('friends')
                    .doc(uid)
                    .collection('friends')
                    .doc(friend.uid)
                    .set(
                      {
                        uid: friend.uid,
                        status: 'pending',
                        name: friend?.name,
                        createdAt: new Date().toISOString()
                      },
                      { merge: true }
                    )
                    .then(() => {
                      db.collection('friends')
                        .doc(friend.uid)
                        .collection('friends')
                        .doc(uid)
                        .set(
                          {
                            uid,
                            status: 'request',
                            name: user.name,
                            createdAt: new Date().toISOString()
                          },
                          { merge: true }
                        )
                        .then(() => {});
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }}
              >
                Add
              </Button>
            </div>
            <Divider />
          </div>
        ))
      ) : (
        <Typography>No users under this name</Typography>
      )}
    </Container>
  );
};

export default SearchUsers;
