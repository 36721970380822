import { Icon } from '@iconify/react';
import alertOutline from '@iconify/icons-eva/alert-triangle-outline';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  // paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 }
}));

// ----------------------------------------------------------------------

TimelineWidget.propTypes = {
  open: PropTypes.bool,
  handleTimelineOpen: PropTypes.func,
  handleTimelineClose: PropTypes.func
};

export default function TimelineWidget({ open, handleTimelineOpen, handleTimelineClose }) {
  const timeline = useSelector((state) => state.reducer.firebase.profile.timeline);
  const succeededTasks = _.filter(timeline, (w) => w === false).length;
  return (
    <RootStyle>
      <Badge showZero badgeContent={succeededTasks} color="error" max={99}>
        <Icon
          icon={alertOutline}
          width={24}
          height={24}
          onClick={open === false ? handleTimelineOpen : handleTimelineClose}
        />
      </Badge>
    </RootStyle>
  );
}
