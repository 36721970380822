/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import react, { useEffect, useState } from 'react';
import {
  Container,
  Stack,
  Typography,
  Alert,
  Button,
  TextField,
  Input,
  IconButton,
  Box,
  Avatar,
  Divider,
  InputBase,
  Card
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../firebase';

const MyMessages = () => {
  const [messages, setMessages] = useState([]);
  const navigate = useNavigate();
  const uid = useSelector((state) => state.reducer.firebase.auth.uid);

  useEffect(() => {
    db.collection('messages')

      .where('users', 'array-contains', uid)
      // .orderBy('lastCreatedAt', 'asc')
      .get()
      .then((querySnapshot) => {
        const holdMessages = [];
        querySnapshot.forEach((doc) => {
          holdMessages.push(doc.data());
        });
        console.log(holdMessages);

        setMessages(holdMessages);
      });
  }, []);
  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
        <Typography variant="h4" gutterBottom>
          Messages coming soon!
        </Typography>
      </Stack>

      {/* {messages.length !== 0 ? (
        messages?.map((message) => (
          <div>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                flex: 1,
                paddingTop: '8px',
                paddingBottom: '8px',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}
                onClick={() => {
                  navigate(
                    `/messages/${message.users[0] === uid ? message.users[1] : message.users[0]}`
                  );
                }}
              >
                {/* <Avatar
                  src={friend.profileImage}
                  alt=""
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: 5,
                    objectFit: 'cover',
                    marginRight: '8px'
                  }}
                />
                <div style={{ width: '100%', flex: 1 }}>
                  <Typography style={{ paddingTop: '8px' }}>
                    {message.users[0] === uid
                      ? message[message.users[1]]
                      : message[message.users[0]]}
                  </Typography>
                  <Typography style={{ paddingBottom: '8px', fontSize: 10, fontWeight: 'lighter' }}>
                    {message.lastMessage}
                  </Typography>
                </div>
              </div>
              <Link
                to={`/messages/${message.users[0] === uid ? message.users[1] : message.users[0]}`}
                style={{
                  textDecoration: 'none',
                  color: '#000000',
                  '&:hover': {
                    cursor: 'pointer'
                  },
                  marginRight: '8px'
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                      '#FBDA61',
                      0.5
                    )} 100%)`,
                    backgroundColor: 'white',
                    color: '#000000'
                  }}
                  onClick={() => {
                    //   db.collection('users').doc(friend.uid).update({friend.requests, {}})
                  }}
                >
                  View
                </Button>
              </Link>
            </div>
            <Divider />
          </div>
        ))
      ) : (
        <Typography>No users under this name</Typography>
      )} */}
    </Container>
  );
};

export default MyMessages;
