import { createSlice } from '@reduxjs/toolkit';

export const popupSlice = createSlice({
  name: 'popup',
  initialState: {
    changeProfile: false
  },
  reducers: {
    changeProfile: (state, action) => {
      state.changeProfile = action.payload;
    }
  }
});

export const { changeProfile } = popupSlice.actions;

// Selectors
export const selectProfilePopup = (state) => state.popup;

export default popupSlice.reducer;
