import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-eva/people-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  // backgroundColor: theme.palette.primary.lighter,
  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
    '#FBDA61',
    0.5
  )} 100%)`
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.text.primary,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.text.primary, 0)} 0%, ${alpha(
    theme.palette.text.primary,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function FriendsTracker() {
  const [numFriends, setNumFriends] = useState(0);
  const friends = useSelector((state) => state.reducer.user.friends);

  useEffect(() => {
    setNumFriends(friends);
  }, [friends]);
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={androidFilled} color="text.primary" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3" color="text.primary">
        {numFriends ? Object.keys(numFriends).length : null}
      </Typography>
      <Typography variant="subtitle2" color="text.primary" sx={{ opacity: 0.72 }}>
        Number of friends
      </Typography>
    </RootStyle>
  );
}
