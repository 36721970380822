// material
import { Box, Grid, Container, Typography } from '@mui/material';
import FriendsTracker from '../components/_dashboard/app/FriendsTracker';
// components
import Page from '../components/Page';
import {
  // AppTasks,
  NumLinksTapped,
  // AppBugReports,
  // AppItemOrders,
  // AppNewsUpdate,
  ProfileVisitsCard,
  ProfileVisitsGraph,
  // AppOrderTimeline,
  AppCurrentVisits
  // AppTrafficBySite,
  // AppCurrentSubject,
  // AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function Statistics() {
  return (
    <Page title="Social Passport | Dashboard">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Your stats</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <ProfileVisitsCard />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NumLinksTapped />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FriendsTracker />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid> */}
        </Grid>
        <Grid container spacing={3} sx={{ marginTop: '15px' }}>
          {/* <Grid item xs={12} md={6} lg={8}>
            <ProfileVisitsGraph />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
