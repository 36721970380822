import {
  Box,
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ModalDescriptionCard = ({ title, description, color }) => (
  <Container sx={{ marginBottom: '8px' }}>
    <Box sx={{ borderRadius: '8px', backgroundColor: color, padding: '8px' }}>
      <Typography
        variant="subtitle1"
        sx={
          {
            //   backgroundColor: 'primary.blue',
            //   display: 'inline-grid',
            //   paddingLeft: '8px',
            //   paddingRight: '8px',
            //   borderRadius: '8px'
          }
        }
      >
        {title}
      </Typography>
      <List>
        {_.map(description, (descriptor) => (
          <ListItem disablePadding>
            <ListItemIcon>{descriptor.icon}</ListItemIcon>
            <ListItemText>
              <Typography lineHeight={1.4} variant="body2">
                {descriptor.name}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  </Container>
);
export default ModalDescriptionCard;
