import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider, MenuItem, Button } from '@mui/material';
import { useState, useRef } from 'react';
import { NavLink as RouterLink, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectCurrentUser } from '../../../store/reducers/userSlice';
import MenuPopover from '../../MenuPopover';
import { auth } from '../../../firebase';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  // paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 }
}));

const MENU_OPTIONS = [
  {
    label: 'Add friend',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Message platon',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Sign up',
    icon: settings2Fill,
    linkTo: '/register'
  }
];

// ----------------------------------------------------------------------

export default function CartWidget() {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(selectCurrentUser);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const sideWidgetAction = (option) => {
    if (option === 'Message platon') {
      window.open(`sms:6096356646&body=Hey, it's _____. Glad we connected!`);
    }
  };

  const logoutHandler = async () => {
    dispatch(logout());
    await auth.signOut();
  };

  return (
    <RootStyle>
      <Icon
        ref={anchorRef}
        icon={plusFill}
        width={24}
        height={24}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onClick={anchorEl === null ? handlePopoverOpen : handlePopoverClose}
        onTouchStart={handlePopoverOpen}
        // onMouseEnter={handlePopoverOpen}
        // onMouseLeave={handlePopoverClose}
      />
      <MenuPopover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={() => {
              sideWidgetAction(option.label);
              handlePopoverClose();
            }}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Link to="/login" style={{ textDecoration: 'none' }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => {
                logoutHandler();
              }}
            >
              Logout
            </Button>
          </Link>
        </Box>
      </MenuPopover>
    </RootStyle>
  );
}
