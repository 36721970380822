import { styled } from '@mui/material/styles';
import { Card, Typography, Stack, Button } from '@mui/material';
import { Link } from 'react-router-dom';
// utils
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, background }) => ({
  display: 'block',
  height: 200,
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 5),
  backgroundColor: theme.palette.grey[background]
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function BottomCard({ color }) {
  return (
    <RootStyle background={color}>
      <Typography variant="h3" color="grey.600" sx={{ alignSelf: 'flex-end' }}>
        Join Social Passport
      </Typography>
      <Typography variant="body2" color="grey.500" sx={{ alignSelf: 'flex-end' }}>
        meet your classmates today
      </Typography>
      <Stack justifyContent="center" direction="row" spacing={2} marginTop="24px">
        <Link
          to="/register"
          style={{
            textDecoration: 'none',
            color: 'text.secondary',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          <Button variant="contained">Join now</Button>
        </Link>
        <Link
          to="/login"
          style={{
            textDecoration: 'none',
            color: 'text.secondary',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        >
          <Button variant="contained">Login</Button>
        </Link>
      </Stack>
    </RootStyle>
  );
}
