import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDxxG0IackJVkWIEkvCCSSzuRBAU1F8nLI',
  authDomain: 'social-passport-a5674.firebaseapp.com',
  projectId: 'social-passport-a5674',
  storageBucket: 'social-passport-a5674.appspot.com',
  messagingSenderId: '320689661212',
  appId: '1:320689661212:web:0ee58f6191a15e06adc6d9',
  measurementId: 'G-X6REJ7ZGPV'
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const storage = firebase.storage().ref();

export { db, auth, storage };
