import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

EmptyLogo.propTypes = {
  sx: PropTypes.object
};
export default function EmptyLogo({ sx }) {
  return (
    <Box
      component="img"
      src="/static/SPlogoEmpty.png"
      sx={{
        padding: '5px',
        width: 40,
        height: 40,
        ...sx
      }}
    />
  );
}
