import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import LinkCard from '../components/mainPage/LinkCard';
import IntroCard from '../components/mainPage/IntroCard';
import BottomCard from '../components/mainPage/BottomCard';
import InfoCard from '../components/mainPage/InfoCard';
import DemoCard from '../components/mainPage/DemoCard';

function HowItWorks() {
  return (
    <Container sx={{ marginTop: '16px' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={8}>
          <IntroCard
            title="How it works"
            description="Ever meet a bunch of awesome people and two weeks later you realize you didnt save their contact information because it would be too chaotic?"
            description2="We solve that. this is the fastest solution to share anything to others at the tap of your phone or scan of a QR code."
          />
        </Grid>
        {isMobile ? null : (
          <Grid item xs={12} sm={6} md={4}>
            <InfoCard
              color="lightBlue"
              icon="ShowList.png"
              text="Features and functionalities social passport provides"
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="pink"
            icon="DemoTapping.png"
            title="Nfc tech"
            description="Think apple pay but your physical passport connects your digital profile"
            grid={3}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="orange"
            icon="QRDemo.png"
            title="Via QR code"
            description="You can also use the QR code on your account to share your profile"
            grid={3}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="yellow"
            icon="ShareDemo.png"
            title="Share your profile!"
            description="Tapping your passport / scanning your QR code will open your profile"
            grid={3}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="lightBlue"
            icon="GroupDemo.png"
            title="Create groups instantly"
            description="Add contacts from CRM to groups with one tap"
            grid={3}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="blue"
            icon="SettingsDemo.png"
            title="Security features"
            description="Lock account and go incognito to hide profile"
            grid={3}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DemoCard
            color="purple"
            icon="ShopDemo.png"
            title="Buy merch"
            description="Buy nfc products that show off your college"
            grid={3}
          />
        </Grid>
      </Grid>
      {/* <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard color="pink" icon="GroupProfile.png" text="create and grow groups instantly" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            color="orange"
            icon="NFCBracelet.png"
            text="get tech enabled custom school swag"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            color="yellow"
            icon="QRCode.png"
            text="connect faster using QR codes and NFC tech"
          />
        </Grid>
      </Grid> */}
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            color="green"
            icon="ContactMessage.png"
            text="Stay in touch with new contacts"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <LinkCard />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={12}>
          <BottomCard color="300" icon="ContactMessage.png" />
        </Grid>
      </Grid>
      <Typography sx={{ height: '50px' }} />
    </Container>
  );
}

export default HowItWorks;
