/* eslint-disable no-restricted-globals */
// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Stack, Button } from '@mui/material';
// utils

import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import EmptyLogo from '../../assets/EmptyLogo';
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  //   alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: 'none',
  height: isMobile || window.innerWidth < 425 ? null : 350,
  textAlign: 'flex-start',
  padding: theme.spacing('24px', '24px'),
  backgroundColor: '#D3E0FF'
}));

// ----------------------------------------------------------------------

export default function LinkCard() {
  return (
    <RootStyle>
      <Stack direction={isMobile || window.innerWidth < 425 ? 'column' : 'row'} spacing={2}>
        <Stack
          sx={{
            flex: 1,
            justifyContent: 'space-between',
            maxHeight: 350,
            alignItems: isMobile || window.innerWidth < 425 ? 'center' : null
          }}
        >
          <Stack
            direction={isMobile || window.innerWidth < 425 ? 'column' : 'row'}
            sx={{ alignItems: 'center', marginBotttom: isMobile ? '16px' : '0px' }}
          >
            <EmptyLogo />
            <div style={{ marginLeft: 5, marginBottom: 0 }}>
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                Social Passport
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Connect with just a tap
              </Typography> */}
            </div>
          </Stack>
          <Stack>
            <Typography
              variant="h3"
              color="grey.800"
              sx={{
                alignSelf: isMobile || window.innerWidth < 425 ? 'center' : null,
                marginBottom: '8px',
                textAlign: isMobile || window.innerWidth < 425 ? 'center' : null
              }}
            >
              Connect faster than ever before!
            </Typography>
            <Typography
              variant="body2"
              sx={{
                opacity: 0.72,
                textAlign: isMobile || window.innerWidth < 425 ? 'center' : null,
                marginBottom: isMobile || window.innerWidth < 425 ? '16px' : '0px'
              }}
            >
              Add links to share your digital presence to fellow classmates
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Link
              to="/howitworks"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button variant="contained">How it works</Button>
            </Link>
            {/* <Link to="/products">
              <Button variant="contained">Buy products</Button>
            </Link> */}
          </Stack>
        </Stack>
        <img
          src="static/LinkApps.png"
          alt="login"
          width="38%"
          style={{
            padding: '8px',
            objectFit: 'cover',
            alignSelf: 'center',
            width: isMobile ? '90%' : '50%',
            marginTop: isMobile ? '24px' : null
          }}
        />
      </Stack>
    </RootStyle>
  );
}
