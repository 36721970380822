/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  DialogActions,
  Button,
  CardContent,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';

PasswordModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handlePasswordCheck: PropTypes.func
};

// ----------------------------------------------------------------------

function PasswordModal({ open, handleClose, handlePasswordCheck }) {
  const [passwordInput, setPasswordInput] = useState('');
  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      fullWidth
      maxWidth="xl"
      disableEscapeKeyDown
      BackdropProps={{
        style: {
          backgroundColor: 'black',
          opacity: '0.99'
        }
      }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          // eslint-disable-next-line no-undef
          onClose(event, reason);
        }
      }}
    >
      <DialogTitle variant="h3">Enter Password!</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box
          sx={{
            '& .MuiTimelineItem-missingOppositeContent:before': {
              display: 'none'
            }
          }}
        >
          <CardContent>
            <TextField
              variant="outlined"
              fullWidth="100%"
              label="Password"
              onChange={(text) => {
                setPasswordInput(text.target.value);
              }}
            />
          </CardContent>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row">
          <Button
            onClick={() => {
              handlePasswordCheck(passwordInput);
            }}
          >
            Enter
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordModal;
