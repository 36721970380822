// material
import { styled } from '@mui/material/styles';
import { Card, Typography, Stack, Button } from '@mui/material';
// utils
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import EmptyLogo from '../../assets/EmptyLogo';
import MainPage from '../../assets/MainPage.png';
// ---------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  height: isMobile ? null : 350,
  textAlign: 'flex-start',
  padding: theme.spacing('24px', '24px', '0px', '24px'),
  backgroundColor: '#D3E0FF'
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function IntroCard({ title, description, description2 }) {
  const [isHovered, setHovered] = useState(false);
  const el = useRef(null);
  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        'Connect with a single tap!',
        'Add friends in real life!',
        'Stay in touch with classmates!'
      ], // Strings to display
      // Speed settings, try diffrent values untill you get good results
      startDelay: 200,
      typeSpeed: 70,
      backSpeed: 30,
      backDelay: 50,
      loop: true
    });
  }, []);
  const user = useSelector((state) => state.reducer.firebase.auth);
  return (
    <RootStyle>
      <Stack direction={isMobile || window.innerWidth < 425 ? 'column' : 'row'} spacing={2}>
        <Stack
          sx={{
            justifyContent: 'space-between',
            maxHeight: 302,
            alignItems: isMobile || window.innerWidth < 425 ? 'center' : null
          }}
        >
          <Stack
            direction={isMobile || window.innerWidth < 425 ? 'column' : 'row'}
            sx={{ alignItems: 'center', marginBotttom: isMobile ? '16px' : '0px' }}
          >
            <EmptyLogo />
            {/* <div style={{ marginLeft: 5, marginBottom: 0 }}>
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                Social Passport
              </Typography>
            </div> */}
          </Stack>
          <Stack>
            <Typography
              variant="h3"
              color="grey.800"
              sx={{
                textAlign: isMobile || window.innerWidth < 425 ? 'center' : null,
                marginBottom: '8px'
              }}
            >
              <span ref={el} />
            </Typography>
            <Typography
              variant="body2"
              sx={{
                opacity: 0.72,
                textAlign: isMobile || window.innerWidth < 425 ? 'center' : null
                // marginBottom: isMobile ? '16px' : '0px'
              }}
            >
              {description}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                opacity: 0.72,
                textAlign: isMobile || window.innerWidth < 425 ? 'center' : null,
                marginBottom: isMobile || window.innerWidth < 425 ? '16px' : '0px'
              }}
            >
              {description2}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Link
              to="/register"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <motion.div animate={{ scale: 1.05 }} transition={{ yoyo: Infinity }}>
                <Button
                  variant="contained"
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                >
                  Join now
                </Button>
              </motion.div>
            </Link>
            <Link
              to={user.isEmpty ? '/login' : '/dashboard/profile'}
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              <Button variant="contained">Login</Button>
            </Link>
            <Link
              to="/products"
              style={{
                textDecoration: 'none',
                color: 'text.secondary',
                '&:hover': {
                  cursor: 'pointer'
                }
              }}
            >
              {/* <motion.div animate={{ scale: 1.1 }} transition={{ yoyo: Infinity }}> */}
              <Button variant="contained">Shop</Button>
              {/* </motion.div> */}
            </Link>
          </Stack>
        </Stack>
        <img
          src={MainPage}
          alt="login"
          width="38%"
          style={{
            padding: '0px',
            objectFit: 'cover',
            alignSelf: 'center',
            width: isMobile ? '90%' : 280,
            marginTop: isMobile ? '24px' : null
          }}
        />
      </Stack>
    </RootStyle>
  );
}
