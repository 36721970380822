// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Button,
  Fab
} from '@mui/material';
// components
import { alpha, styled } from '@mui/material/styles';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { setFriends } from '../store/reducers/userSlice';
import { db } from '../firebase';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 16,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  height: '75px',
  bottom: 16,
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // paddingTop: theme.spacing(1.25),
  // backgroundColor: 'white',
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 }
}));

export default function FriendsList() {
  const { id } = useParams();
  const [friends, setFriendArray] = useState([]);
  const [label, setLabel] = useState('friends');

  const handleChange = (hold) => {
    setLabel(hold);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    db.collection('friends')
      .doc(id)
      .collection('friends')
      .onSnapshot((snapshot) => {
        const holdFriends = [];
        snapshot.forEach((doc) => {
          holdFriends.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setFriends(holdFriends));
        setFriendArray(holdFriends);
      });
  }, [id]);
  const navigate = useNavigate();

  return (
    <Page title="Social Passport | User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom mt={5}>
            Connections
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableBody>
                  {friends
                    ? Object.keys(friends).map((friend) => {
                        const name = friends[friend]?.name;
                        const createdAt = friends[friend]?.createdAt;
                        const incognitoMode = false;
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <div>
                            {incognitoMode ? null : (
                              <div>
                                <TableRow
                                  hover
                                  key={friend.uid}
                                  tabIndex={-1}
                                  role="checkbox"
                                  sx={{
                                    display: 'table',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                  }}
                                >
                                  <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="column" marginLeft="16px" marginY="12px">
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                      <Typography variant="body2" noWrap>
                                        {`added ${moment(createdAt).format('MM')}/${moment(
                                          createdAt
                                        ).format('DD')}/${moment(createdAt).format('YY')}`}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                </TableRow>
                              </div>
                            )}
                          </div>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
        <Link to={`/profile/${id}`}>
          <RootStyle>
            <Fab
              size="medium"
              color="secondary"
              aria-label="add"
              style={{
                backgroundColor: 'transparent',
                color: 'gray',
                backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                  '#FBDA61',
                  0.5
                )} 100%)`,
                borderRadius: '8px'
              }}
            >
              <ChevronLeft />
            </Fab>
          </RootStyle>
        </Link>
      </Container>
    </Page>
  );
}
