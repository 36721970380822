import { Box, Typography, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { storage } from '../../../firebase';
import { selectCurrentUser } from '../../../store/reducers/userSlice';

const AccountStyle = styled('div')(() => ({
  alignItems: 'center',
  flexDirection: 'row',
  display: 'flex',
  marginBottom: 10
}));

const DescriptionStyle = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'flex-start',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

export default function ProfileHeader() {
  const user = useSelector(selectCurrentUser);
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(user?.profileImage);
  }, [user]);

  return (
    <Box sx={{ mb: 2 }}>
      <DescriptionStyle>
        <AccountStyle>
          <Avatar
            src={url}
            alt=""
            style={{ width: 45, height: 45, borderRadius: 5, objectFit: 'cover' }}
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user?.name ? user.name : 'loading...'}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user?.email ? user.email : 'loading...'}
            </Typography>
          </Box>
        </AccountStyle>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {user?.description}
        </Typography>
      </DescriptionStyle>
    </Box>
  );
}
