import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectUser } from '../store/reducers/reducer';

ChangeProfileModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  addedDescription: PropTypes.string,
  addedName: PropTypes.string,
  setAddedDescription: PropTypes.func,
  setAddedName: PropTypes.func,
  updateProfile: PropTypes.func,
  handleSnackbarOpen: PropTypes.func
};

function ChangeProfileModal(props) {
  const user = useSelector(selectUser);

  const {
    open,
    handleClose,
    addedDescription,
    addedName,
    setAddedDescription,
    setAddedName,
    updateProfile,
    handleSnackbarOpen
  } = props;
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Edit profile</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ marginBottom: '10px' }}>
          {user?.description !== undefined ? 'Change description' : 'Add description'}
        </DialogContentText>
        <TextField
          sx={{ marginBottom: '30px' }}
          autoFocus
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          value={addedDescription}
          error={addedDescription === ''}
          onChange={(event) => setAddedDescription(event.target.value)}
        />
        <DialogContentText sx={{ marginBottom: '10px' }}>Change name</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={addedName || user?.name}
          onChange={(event) => setAddedName(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleSnackbarOpen();
            updateProfile();
            handleClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ChangeProfileModal;
