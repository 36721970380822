/* eslint-disable prettier/prettier */
import { filter } from 'lodash';
import { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
// material
import { alpha } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  TextField,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
// components
import { useSelector } from 'react-redux';
import { selectLinks, selectShowLinks } from '../store/reducers/reducer';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar } from '../components/_dashboard/user';
//
import USERLIST from '../_mocks_/user';

// ----------------------------------------------------------------------

const TABLE_HEAD_WEB = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'value', label: 'Link', alignRight: false }
];

const TABLE_HEAD_MOBILE = [{ id: 'name', label: 'Name', alignRight: false }];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MyLinks() {
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  // eslint-disable-next-line prettier/prettier
  const links = useSelector(selectLinks);
  const showLinks = useSelector(selectShowLinks);
  const [showLinksInput, setShowLinksInput] = useState({
    showCall: showLinks?.showCall,
    showInstagram: showLinks?.showInstagram,
    showSafari: showLinks?.showSafari,
    showEmail: showLinks?.showEmail,
    showWhatsApp: showLinks?.showWhatsApp,
    showiMessage: showLinks?.showiMessage,
    showVenmo: showLinks?.showVenmo

    // Stanford: links?.Stanford
  });
  const [textInput, setTextInput] = useState({
    Call: links?.Call,
    Instagram: links?.Instagram,
    Safari: links?.Safari,
    Email: links?.Email,
    Venmo: links?.Venmo
    // Stanford: links?.Stanford
  });

  const getName = (name) => {
    switch (name) {
      case 'Call':
        return 'Phone Number';
      case 'Instagram':
        return 'Instagram username (ex. platon_maza)';
      case 'iMessage':
        return 'iMessage';
      case 'WhatsApp':
        return 'WhatsApp';
      case 'Venmo':
        return 'Venmo';
      default:
        return `${name} link`;
    }
  };

  useEffect(() => {
    if (links) {
      setSelected(Object.keys(links));
      setTextInput(links);
      setShowLinksInput(showLinks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links, showLinks]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getLinkValue = (name) => {
    switch (name) {
      case 'WhatsApp':
        return textInput.Call;
      case 'iMessage':
        return textInput.Call;
      default:
        return textInput[name];
    }
  };

  const handleSelectAllClick = () => {
    setShowLinksInput({
      showCall: true,
      showInstagram: true,
      showSafari: true,
      showEmail: true,
      showWhatsApp: true,
      showiMessage: true,
      showVenmo: true
    });
  };

  const handleClick = (name) => {
    if (name === 'Instagram') {
      setShowLinksInput({ ...showLinksInput, showInstagram: !showLinksInput[`show${name}`] });
    }
    if (name === 'Call') {
      setShowLinksInput({ ...showLinksInput, showCall: !showLinksInput[`show${name}`] });
    }
    if (name === 'WhatsApp') {
      setShowLinksInput({ ...showLinksInput, showWhatsApp: !showLinksInput[`show${name}`] });
    }
    if (name === 'Email') {
      setShowLinksInput({ ...showLinksInput, showEmail: !showLinksInput[`show${name}`] });
    }
    if (name === 'iMessage') {
      setShowLinksInput({ ...showLinksInput, showiMessage: !showLinksInput[`show${name}`] });
    }
    if (name === 'Safari') {
      setShowLinksInput({ ...showLinksInput, showSafari: !showLinksInput[`show${name}`] });
    }
    if (name === 'Venmo') {
      setShowLinksInput({ ...showLinksInput, showVenmo: !showLinksInput[`show${name}`] });
    }
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Social Passport | User">
      <Container>
        <div>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography variant="h4" gutterBottom>
              Your links
            </Typography>
          </Stack>

          <Card>
            <UserListToolbar
              links={textInput}
              showLinks={showLinksInput}
              numSelected={selected.length}
              filterName={filterName}
              onFilterName={handleFilterByName}
            />
            <Scrollbar>
              <TableContainer>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={isMobile ? TABLE_HEAD_MOBILE : TABLE_HEAD_WEB}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers.map((row) => {
                      const { id, name, avatarUrl } = row;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                          <TableCell padding="checkbox">
                            <Checkbox
                              style={{
                                marginRight: 20
                              }}
                              checked={showLinksInput[`show${name}`]}
                              onChange={() => handleClick(name)}
                            />
                          </TableCell>
                          {isMobile ? null : (
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              style={{ margin: 0, padding: 0 }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                style={{ margin: 0, padding: 0 }}
                              >
                                {isMobile ? null : <Avatar alt={name} src={avatarUrl} />}
                              </Stack>
                            </TableCell>
                          )}
                          <TableCell align="left" width="100%">
                            <TextField
                              id="outlined-basic"
                              label={links?.name ? '' : getName(name)}
                              onChange={(event) => {
                                if (name === 'Call') {
                                  setTextInput({ ...textInput, Call: event.target.value });
                                }
                                if (name === 'Email') {
                                  setTextInput({ ...textInput, Email: event.target.value });
                                }
                                if (name === 'Instagram') {
                                  setTextInput({ ...textInput, Instagram: event.target.value });
                                }
                                if (name === 'Safari') {
                                  setTextInput({ ...textInput, Safari: event.target.value });
                                }
                                if (name === 'WhatsApp') {
                                  setTextInput({ ...textInput, Call: event.target.value });
                                }
                                if (name === 'iMessage') {
                                  setTextInput({ ...textInput, Call: event.target.value });
                                }
                                if (name === 'Venmo') {
                                  setTextInput({ ...textInput, Venmo: event.target.value });
                                }
                              }}
                              value={getLinkValue(name)}
                              variant="outlined"
                              style={{ width: '100%' }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </div>
      </Container>
    </Page>
  );
}
