// material
import { Container, Typography, Button, Fab, Box, MenuItem } from '@mui/material';
import firebase from 'firebase';

// components
import { alpha, styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, Link as RouterLink } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import homeFill from '@iconify/icons-eva/plus-outline';
import personFill from '@iconify/icons-eva/person-outline';
import lockOutline from '@iconify/icons-eva/lock-outline';
import settings2Fill from '@iconify/icons-eva/shopping-bag-outline';
import sendTap from '@iconify/icons-eva/message-circle-outline';

import { Icon } from '@iconify/react';
import { StickyContainer, Sticky } from 'react-sticky';
import MenuPopover from '../components/MenuPopover';
import { db } from '../firebase';
import ProfileHeader from '../components/_dashboard/profile/ProfileHeader';
import Page from '../components/Page';
import { ProductList } from '../components/_dashboard/profile';
import PasswordModal from '../components/modals/PasswordModal';
import { currentUser } from '../store/reducers/userSlice';

//
import PRODUCTS from '../_mocks_/profile';
import CreateAccountModal from '../components/modals/CreateAccountModal';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 16,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  width: '80%',
  height: '75px',
  bottom: 16,
  // paddingLeft: theme.spacing(2),
  // paddingRight: theme.spacing(2),
  // paddingTop: theme.spacing(1.25),
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 }
}));

export default function Profile2() {
  // const [messagePop, setMessagePop] = useState(false);
  const [createAccountModal, setCreateAccountModal] = useState(false);
  const [userFriends, setUserFriends] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { id } = useParams();
  const [action, setAction] = useState('');
  const [isFriend, setIsFriend] = useState(false);

  const [friends, setFriends] = useState();
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userRef = db.collection('public').doc(id);
  const [openPassword, setOpenPassword] = useState(false);
  const user = useSelector((state) => state?.reducer?.firestore?.data?.users?.[id]);
  const notLoggedIn = useSelector((state) => state?.reducer?.firebase?.auth?.isEmpty);
  const loggedInUser = useSelector((state) => state?.reducer?.firebase?.auth);
  const userProfile = useSelector((state) => state?.reducer?.user?.currentUser);
  const myUserProfile = useSelector((state) => state?.reducer?.user);
  const needKey = useSelector((state) => state?.reducer?.user?.currentUser?.settings?.needKey);
  const firstName = user?.name.split(' ')[0];
  const passwordKey = useSelector(
    (state) => state?.reducer?.user?.currentUser?.settings?.passwordKey
  );

  const MENU_OPTIONS = [
    {
      label: 'Add friend',
      icon: homeFill,
      button: true
    },
    {
      label: 'Create account',
      icon: personFill,
      button: true
    },
    {
      label: 'Buy passport',
      icon: settings2Fill,
      linkTo: '/dashboard/products'
    },
    {
      label: 'Send message',
      icon: sendTap,
      button: true
    }
  ];

  const handleFAB = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handlePasswordClose = () => {
    setOpenPassword(false);
  };
  const handlePasswordCheck = (password) => {
    setOpenPassword(!(password === passwordKey));
  };
  const handleCreateAccountClose = () => {
    setCreateAccountModal(false);
  };

  useEffect(() => {
    checkIfFriend();
  }, []);

  const checkIfFriend = () => {
    console.log(myUserProfile.friends.map((user) => user.uid).includes(id));
    setIsFriend(myUserProfile.friends.map((user) => user.uid).includes(id));
  };

  useEffect(() => {
    db.collection('friends')
      .doc(id)
      .collection('friends')
      .onSnapshot((snapshot) => {
        const holdFriends = [];
        snapshot.forEach((doc) => {
          holdFriends.push({ ...doc.data(), id: doc.id });
        });
        setUserFriends(holdFriends);
      });
  }, [id]);

  const handleAddFriend = () => {
    if (!notLoggedIn) {
      db.collection('friends')
        .doc(loggedInUser?.uid)
        .collection('friends')
        .doc(id)
        .set(
          {
            uid: id,
            status: 'pending',
            name: userProfile?.name,
            createdAt: new Date().toISOString()
          },
          { merge: true }
        )
        .then(() => {
          db.collection('friends')
            .doc(id)
            .collection('friends')
            .doc(loggedInUser?.uid)
            .set(
              {
                uid: myUserProfile.uid,
                status: 'request',
                name: myUserProfile.name,
                createdAt: new Date().toISOString()
              },
              { merge: true }
            )
            .then(() => {});
        })
        .catch((error) => {
          console.log(error);
        });
      navigate('/dashboard/user', { replace: true });
    } else {
      setCreateAccountModal(true);
    }
  };

  // const photos = useSelector((state) => state.reducer.user.photos);
  useEffect(() => {
    setOpenPassword(needKey);
    userRef
      .get()
      .then((doc) => {
        if (doc.data().uid) {
          setUserInfo(doc.data());
          dispatch(currentUser(doc.data()));
          userRef.update({
            'stats.visits': firebase.firestore.FieldValue.increment(1)
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needKey]);

  return (
    <Page title="Social Passport | Profile">
      <div style={{ margin: '16px' }}>
        {userInfo?.settings?.lockAccount ? (
          <Typography>User has gone private</Typography>
        ) : (
          <div style={{ justifyContent: 'space-between', height: '100vh' }}>
            <div>
              <ProfileHeader />
              <div style={{ flexDirection: 'row', display: 'flex', width: '100%', flex: 1 }}>
                {!isFriend || notLoggedIn ? (
                  <Button
                    variant="contained"
                    sx={{
                      mb: 4,
                      mt: '8px',
                      marginRight: '8px',
                      backgroundColor: 'white',
                      color: 'gray',
                      backgroundImage: `linear-gradient(135deg, ${alpha(
                        '#FF5ACD',
                        0.5
                      )} 0%, ${alpha('#FBDA61', 0.5)} 100%)`,
                      boxShadow: 0
                    }}
                    fullWidth="100%"
                    onClick={() => {
                      setAction('AddFriend');
                      handleAddFriend();
                    }}
                  >
                    Add friend
                  </Button>
                ) : null}

                {notLoggedIn ? (
                  <Button
                    variant="outlined"
                    sx={{
                      mb: 4,
                      mt: '8px',
                      borderColor: 'gray',
                      color: 'gray',
                      marginRight: '8px'
                    }}
                    fullWidth="100%"
                    onClick={() => {
                      if (notLoggedIn) {
                        setAction('Message');
                        setCreateAccountModal(true);
                      }
                    }}
                  >
                    Message
                  </Button>
                ) : (
                  <Link
                    to={`/messages/${id}`}
                    style={{
                      textDecoration: 'none',
                      color: '#000000',
                      '&:hover': {
                        cursor: 'pointer'
                      },
                      marginRight: '8px',
                      width: '100%'
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        mb: 4,
                        mt: '8px',
                        borderColor: 'gray',
                        color: 'gray'
                      }}
                      fullWidth="100%"
                    >
                      Message
                    </Button>
                  </Link>
                )}

                <Link to="friends">
                  <Button
                    variant="outlined"
                    sx={{ mb: 4, mt: '8px', width: '5%', borderColor: 'gray', color: 'gray' }}
                    startIcon={<Icon icon={personFill} />}
                  >
                    {userFriends ? userFriends.length : 0}
                  </Button>
                </Link>
              </div>

              <Typography
                style={{
                  font: 20,
                  fontWeight: 'bold',
                  marginBottom: '16px',
                  textAlign: 'center'
                }}
              >
                Tap to use links!
              </Typography>
              <ProductList products={PRODUCTS} user={userInfo} />
            </div>
            <RootStyle
            // style={{
            //   flexDirection: 'row',
            //   flex: 1,
            //   display: 'flex',
            //   justifyContent: 'space-between',
            //   position: 'sticky'
            // }}
            >
              {notLoggedIn ? (
                <Button
                  variant="outlined"
                  style={{
                    height: '48px',
                    marginRight: '16px',
                    width: '100%',
                    color: 'gray',
                    backgroundColor: 'white'
                  }}
                  onClick={() => {
                    setCreateAccountModal(true);
                  }}
                >
                  Create account now
                </Button>
              ) : (
                <Button
                  variant="text"
                  style={{ height: '48px', width: '80%' }}
                  onClick={() => {
                    setCreateAccountModal(true);
                  }}
                />
              )}
              <Fab
                size="medium"
                color="secondary"
                aria-label="add"
                style={{
                  backgroundColor: 'white',
                  color: 'gray',
                  backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                    '#FBDA61',
                    0.5
                  )} 100%)`,
                  borderRadius: '8px',
                  alignContent: 'flex-start',
                  padding: 0,
                  width: notLoggedIn ? '75px' : '55px',
                  height: '55px'
                }}
                onClick={handleFAB}
              >
                <AddIcon />
              </Fab>
              <MenuPopover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ width: 230, margin: 0, padding: 0 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                {MENU_OPTIONS.map((option) => (
                  <>
                    {option.linkTo ? (
                      <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={() => {
                          handleClose();
                        }}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        <Box
                          component={Icon}
                          icon={option.icon}
                          sx={{
                            mr: 2,
                            width: 24,
                            height: 24
                          }}
                        />

                        {option.label}
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => {
                          if (option.button) {
                            if (option.label === 'Add friend') {
                              handleAddFriend();
                            } else if (option.label === 'Create account') {
                              setCreateAccountModal(true);
                            } else if (option.label === 'Send message') {
                              navigate(`/messages/${id}`);
                            }
                          }
                          handleClose();
                        }}
                        sx={{ typography: 'body2', py: 1, px: 2.5 }}
                      >
                        <Box
                          component={Icon}
                          icon={option.icon}
                          sx={{
                            mr: 2,
                            width: 24,
                            height: 24,
                            color:
                              option.label === 'Send message' && notLoggedIn ? 'lightgray' : 'black'
                          }}
                        />
                        <Typography
                          style={{
                            color:
                              option.label === 'Send message' && notLoggedIn ? 'lightgray' : 'black'
                          }}
                        >
                          {option.label}
                        </Typography>
                      </MenuItem>
                    )}
                  </>
                ))}

                {!notLoggedIn ? null : (
                  <Box sx={{ p: 2, pt: 1.5 }}>
                    <Link to="/Login" style={{ textDecoration: 'none' }}>
                      <Button
                        fullWidth
                        color="inherit"
                        variant="outlined"
                        onClick={() => {
                          setCreateAccountModal();
                        }}
                      >
                        Log in!
                      </Button>
                    </Link>
                  </Box>
                )}
              </MenuPopover>
            </RootStyle>
          </div>
        )}
      </div>
      <PasswordModal
        open={openPassword}
        handleClose={handlePasswordClose}
        handlePassword
        handlePasswordCheck={handlePasswordCheck}
      />

      <CreateAccountModal
        open={createAccountModal}
        handleClose={handleCreateAccountClose}
        action={action}
        id={id}
      />
    </Page>
  );
}
