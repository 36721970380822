/* eslint-disable react/prop-types */
import React, { useState, SyntheticEvent } from 'react';
import firebase from 'firebase';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Box,
  Tabs,
  Tab,
  AppBar,
  Typography,
  IconButton,
  InputAdornment
} from '@mui/material';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SwipeableViews from 'react-swipeable-views/lib/SwipeableViews';
import { auth } from '../../firebase';
import { login } from '../../store/reducers/userSlice';

CreateAccountModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingTop: '10px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
}

export default function CreateAccountModal(props) {
  const { open, handleClose, action, id } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeIndex = (index) => {
  //   setValue(index);
  // };
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.reducer?.user?.currentUser);
  const navigate = useNavigate();

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleCreateAccount = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        auth.currentUser.updateProfile({
          displayName: name
        });
        firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .set({
            name,
            email: user.email,
            phone: '',
            uid: firebase.auth().currentUser.uid,
            createdAt: new Date().toISOString(),
            links: {
              Email: email,
              ContactInfo: 'true'
            },
            timeline: {
              0: false,
              1: false,
              2: false,
              3: false,
              4: false
            },
            showLinks: {
              showCall: false,
              showInstagram: false,
              showSafari: false,
              showEmail: true,
              showWhatsApp: false,
              showiMessage: false,
              showContactInfo: true,
              showStanford: false
            },
            stats: {
              visits: 0,
              call: 0,
              whatsApp: 0,
              iMessage: 0,
              email: 0
            },
            settings: {
              linkAccount: false,
              incognito: false,
              needKey: false,
              passwordKey: ''
            }
          });
        // ...
        firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .collection('friends')
          .doc(currentUser.uid)
          .set({
            name: currentUser.name,
            uid: currentUser.uid,
            createdAt: new Date().toISOString(),
            status: 'active'
          });
        dispatch(
          login({
            email,
            uid: userCredential.user.uid,
            name
          })
        );
      });
    if (action === 'AddFriend') {
      navigate('/dashboard/user', { replace: true, state: { register: true } });
    } else if (action === 'Message') {
      navigate(`/messages/${id}`);
    } else {
      navigate('dashboard/profile');
    }
  };

  const handleLoginAccount = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const { user } = userCredential;
        firebase.firestore().collection('users').doc(user.uid).set(
          {
            lastSignIn: new Date().toISOString()
          },
          { merge: true }
        );
        firebase
          .firestore()
          .collection('users')
          .doc(firebase.auth().currentUser.uid)
          .collection('friends')
          .doc(currentUser.uid)
          .set({
            name: currentUser.name,
            uid: currentUser.uid,
            createdAt: new Date().toISOString(),
            status: 'active'
          });
        dispatch(
          login({
            email: user.email,
            uid: user.uid,
            name: user.displayName
          })
        );
      })
      .catch((error) => {
        alert(error);
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // if (errorCode === 'auth/wrong-password') {
        //   setWrongPassword(errorMessage);
        // }
      });
    if (action === 'AddFriend') {
      navigate('/dashboard/user', { replace: true, state: { register: true } });
    } else if (action === 'Message') {
      navigate(`/messages/${id}`);
    } else {
      navigate('dashboard/profile');
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Register" {...a11yProps(0)} />
            <Tab label="Login" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          // onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <DialogTitle>Create account to add friend!</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ marginBottom: '10px' }}>
                Add information below
              </DialogContentText>
              <TextField
                sx={{ marginBottom: '16px' }}
                autoFocus
                margin="dense"
                label="Full name"
                type="text"
                fullWidth
                variant="outlined"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
              <TextField
                sx={{ marginBottom: '16px' }}
                margin="dense"
                label="Email"
                type="text"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                sx={{ marginBottom: '16px' }}
                autoComplete="current-password"
                name="password"
                margin="dense"
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword} edge="end">
                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                variant="outlined"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </DialogContent>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <Box style={{ padding: 0 }}>
              <DialogTitle>Enter login information!</DialogTitle>
              <DialogContent>
                <TextField
                  sx={{ marginBottom: '16px' }}
                  margin="dense"
                  label="Email"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
                <TextField
                  sx={{ marginBottom: '16px' }}
                  autoComplete="current-password"
                  name="password"
                  margin="dense"
                  label="Password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  variant="outlined"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
              </DialogContent>
            </Box>
          </TabPanel>
        </SwipeableViews>
      </Box>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            if (value === 0) {
              handleCreateAccount();
            } else {
              handleLoginAccount();
            }
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
