import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
// layouts
import firebase from 'firebase';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import ProfileLayout from './layouts/ProfileLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Products from './pages/Products';
import Blog from './pages/Blog';
import NotFound from './pages/Page404';
import Profile from './pages/Profile';
import DashboardApp from './pages/DashboardApp';
import MyLinks from './pages/MyLinks';
import MyProfile from './pages/MyProfile';
import Checkout from './pages/Checkout';
import Users from './pages/Users';
import Statistics from './pages/Statistics';
import MainPage from './pages/MainPage';
import Settings from './pages/Settings';
import HowItWorks from './pages/HowItWorks';
import Profile2 from './pages/Profile2';
import FriendsList from './pages/FriendsList';
import Messages from './pages/Messages';
import SearchUsers from './pages/Search';
import MyMessages from './pages/MyMessages';

// ----------------------------------------------------------------------
// element: uid ? <DashboardLayout /> : <Navigate to="/login" />,
export default function Router() {
  firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      localStorage.setItem('loggedIn', true);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.setItem('loggedIn', false);
      localStorage.setItem('user', null);
    }
  });
  return useRoutes([
    {
      path: '/dashboard',
      element:
        JSON.parse(localStorage.getItem('loggedIn')) === true ? (
          <DashboardLayout />
        ) : (
          <Navigate to="/" />
        ),
      children: [
        { element: <Navigate to="/dashboard/profile" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'profile', element: <MyProfile /> },
        { path: 'stats', element: <Statistics /> },
        { path: 'checkout', element: <Checkout /> },
        { path: 'links', element: <MyLinks /> },
        { path: 'user', element: <Users /> },
        { path: 'settings', element: <Settings /> },
        { path: 'search', element: <SearchUsers /> },
        { path: 'messages', element: <MyMessages /> }
        // { path: 'messages/:id', element: <Messages /> }
      ]
    },
    {
      path: '/',
      element:
        JSON.parse(localStorage.getItem('loggedIn')) === true ? (
          <DashboardLayout />
        ) : (
          <ProfileLayout />
        ),
      children: [
        {
          path: '/',
          element:
            JSON.parse(localStorage.getItem('loggedIn')) === true ? <MyProfile /> : <MainPage />
        },
        { path: '/howitworks', element: <HowItWorks /> },
        { path: '/products', element: <Products /> },
        { path: 'profile/:id', element: <Profile2 /> },
        { path: 'products', element: <Products /> }
      ]
    },

    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'privacy-policy', element: <Login /> },
        { path: 'termsofservice', element: <Login /> },
        { path: '404', element: <NotFound /> },
        // { path: 'products', element: <Products /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/',
      element: <ProfileLayout />,
      children: [
        { path: 'profile/:id', element: <Profile2 /> },
        { path: 'profile/:id/friends', element: <FriendsList /> }
      ]
    },
    { path: 'messages/:id', element: <Messages /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
