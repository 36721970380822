import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-outline';
import personFill from '@iconify/icons-eva/person-outline';
import settings2Fill from '@iconify/icons-eva/settings-2-outline';
import createAccount from '@iconify/icons-eva/person-add-outline';
import shoppingCart from '@iconify/icons-eva/shopping-cart-outline';
import howitworks from '@iconify/icons-eva/book-open-outline';
import { Link, Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, IconButton, Avatar } from '@mui/material';
// components
import { useDispatch, useSelector } from 'react-redux';
import MenuPopover from '../../components/MenuPopover';
//
import { logout } from '../../store/reducers/userSlice';
import { selectUser } from '../../store/reducers/reducer';
import { auth, storage } from '../../firebase';
import { changeProfile } from '../../store/reducers/popupSlice';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Edit Profile',
    icon: personFill,
    linkTo: '/dashboard/links'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '/dashboard/settings'
  }
];

const MAIN_PAGE_OPTIONS = [
  {
    label: 'Create Account',
    icon: createAccount,
    linkTo: '/register'
  },
  {
    label: 'Shop',
    icon: shoppingCart,
    linkTo: '/products'
  },
  {
    label: 'How It Works',
    icon: howitworks,
    linkTo: '/howitworks'
  }
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const uid = useSelector((state) => state.reducer.firebase.auth.uid);
  const [url, setUrl] = useState('');
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const logoutHandler = async () => {
    dispatch(logout());
    await auth.signOut();
  };

  useEffect(() => {
    setUrl(user.profileImage);
  }, [user]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar
          src={JSON.parse(localStorage.getItem('loggedIn')) === true ? url : null}
          alt=""
          style={{ width: 45, height: 45, borderRadius: 30, objectFit: 'cover' }}
        />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {JSON.parse(localStorage.getItem('loggedIn')) === true ? (
          <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {user?.name ? user.name : 'Loading'}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {user?.email ? user.email : 'Loading'}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            {MENU_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={() => {
                  if (option.label === 'Profile') {
                    dispatch(changeProfile(true));
                  }
                  handleClose();
                }}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            ))}
          </>
        ) : (
          <>
            {MAIN_PAGE_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={() => {
                  if (option.label === 'Profile') {
                    dispatch(changeProfile(true));
                  }
                  handleClose();
                }}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            ))}
          </>
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Link to="/Login" style={{ textDecoration: 'none' }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => {
                logoutHandler();
              }}
            >
              {JSON.parse(localStorage.getItem('loggedIn')) === true ? 'Logout' : 'Login'}
            </Button>
          </Link>
        </Box>
      </MenuPopover>
    </>
  );
}
