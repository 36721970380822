import { Button, TextField, ToggleButtonGroup, ToggleButton, TableContainer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const box = '#EEEEEE';

export const _ToggleButton = styled(ToggleButton)({
  // borderRadius: 20,
  // border: "5px solid blue",
  // maxWidth: "100px",
});

export const _ToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: 100,
  background: box,
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.7),
    border: 0,
    '&.Mui-disabled': {
      border: 0
    },
    '&:not(:first-of-type)': {
      borderRadius: 20
    },
    '&:first-of-type': {
      borderRadius: 20
    }
  }
}));
