import * as Yup from 'yup';
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { login } from '../../../store/reducers/userSlice';
import { auth } from '../../../firebase';
// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const phoneRegExp = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const dispatch = useDispatch();
  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    phone: Yup.string()
      .min(10, 'Too short!')
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('Phone number is required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password)
        .then((userCredential) => {
          const { user } = userCredential;
          auth.currentUser.updateProfile({
            displayName: `${values.firstName} ${values.lastName}`
          });
          firebase
            .firestore()
            .collection('users')
            .doc(firebase.auth().currentUser.uid)
            .set({
              name: `${values.firstName} ${values.lastName}`,
              email: user.email,
              phone: values.phone,
              uid: firebase.auth().currentUser.uid,
              createdAt: new Date().toISOString(),
              links: {
                Call: values.phone,
                WhatsApp: values.phone,
                iMessage: values.phone,
                Email: values.email,
                ContactInfo: 'true'
              },
              timeline: {
                0: false,
                1: false,
                2: false,
                3: false,
                4: false
              },
              showLinks: {
                showCall: true,
                showInstagram: false,
                showSafari: false,
                showEmail: true,
                showWhatsApp: false,
                showiMessage: true,
                showContactInfo: true,
                showStanford: false
              },
              stats: {
                visits: 0,
                call: 0,
                whatsApp: 0,
                iMessage: 0,
                email: 0
              }
            })
            .then(() => {
              firebase
                .firestore()
                .collection('public')
                .doc(firebase.auth().currentUser.uid)
                .set({
                  name: `${values.firstName} ${values.lastName}`,

                  email: user.email,

                  uid: firebase.auth().currentUser.uid,

                  links: {
                    Call: values.phone,
                    WhatsApp: values.phone,
                    iMessage: values.phone,
                    Email: values.email,
                    ContactInfo: 'true'
                  },
                  showLinks: {
                    showCall: true,
                    showInstagram: false,
                    showSafari: false,
                    showEmail: true,
                    showWhatsApp: false,
                    showiMessage: true,
                    showContactInfo: true,
                    showStanford: false
                  },
                  settings: {
                    linkAccount: false,
                    incognito: false,
                    needKey: false,
                    passwordKey: ''
                  }
                });
            });
          // ...
          dispatch(
            login({
              email: values.email,
              uid: userCredential.user.uid,
              name: `${values.firstName} ${values.lastName}`
            })
          );
        })
        .catch((error) => {
          alert(error);
        });
      navigate('/dashboard/profile', { replace: true, state: { register: true } });
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              type="firstName"
              name="firstName"
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              label="Last name"
              type="lastName"
              name="lastName"
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
          <TextField
            fullWidth
            type="phone"
            name="phone"
            label="Phone number, add area code (Ex. 1 for US)"
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            name="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
