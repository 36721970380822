import PropTypes from 'prop-types';
// import { Icon } from '@iconify/react';
// import searchFill from '@iconify/icons-eva/search-fill';
// material
import { alpha, styled } from '@mui/material/styles';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Toolbar, Tooltip, Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, db } from '../../../firebase';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

// const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
//   width: 240,
//   transition: theme.transitions.create(['box-shadow', 'width'], {
//     easing: theme.transitions.easing.easeInOut,
//     duration: theme.transitions.duration.shorter
//   }),
//   '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
//   '& fieldset': {
//     borderWidth: `1px !important`,
//     borderColor: `${theme.palette.grey[500_32]} !important`
//   }
// }));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  links: PropTypes.object,
  showLinks: PropTypes.object,
  numSelected: PropTypes.number
  // filterName: PropTypes.string,
  // onFilterName: PropTypes.func
};

export default function UserListToolbar({
  links,
  showLinks,
  numSelected
  // filterName,
  // onFilterName
}) {
  const validLinks = Object.fromEntries(
    Object.entries(links).filter(([, v]) => v != null || v === '')
  );

  const timeline = useSelector((state) => state.reducer.firebase.profile.timeline);

  const addLinks = () => {
    validLinks.WhatsApp = validLinks.Call;
    validLinks.iMessage = validLinks.Call;
    db.collection('public')
      .doc(auth.currentUser.uid)
      .set(
        // eslint-disable-next-line object-shorthand
        { links: validLinks, showLinks: showLinks, timeline: { ...timeline, 0: true } },
        { merge: true }
      );
    db.collection('users')
      .doc(auth.currentUser.uid)
      .set(
        // eslint-disable-next-line object-shorthand
        { links: validLinks, showLinks: showLinks, timeline: { ...timeline, 0: true } },
        { merge: true }
      );
  };

  return (
    <RootStyle
      sx={{
        backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
          '#FBDA61',
          0.5
        )} 100%)`
      }}
    >
      {/* {numSelected > 0 ? null : (
        // <Typography component="div" variant="subtitle1">
        //   {numSelected} selected
        // </Typography>
        <SearchStyle
          value={filterName}
          onChange={onFilterName}
          placeholder="Search link name ..."
          startAdornment={
            <InputAdornment position="start">
              <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          }
        />
      )} */}
      <Tooltip title="Delete">
        <Link to="/dashboard/profile">
          <Button
            variant="contained"
            endIcon={<SaveAltIcon />}
            sx={{ backgroundColor: 'white', color: '#377DF7' }}
            onClick={() => addLinks()}
          >
            Save
          </Button>
        </Link>
      </Tooltip>
      {/* // <Tooltip title="Filter list">
        //   <IconButton>
        //     <Icon icon={roundFilterList} />
        //   </IconButton>
        // </Tooltip> */}
      {/* } */}
    </RootStyle>
  );
}
