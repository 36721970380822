import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
// utils
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, background }) => ({
  width: '100%',
  display: 'block',
  height: 350,
  justifyContent: 'space-between',
  boxShadow: 'none',
  textAlign: 'center',
  alignItems: 'center',
  alignContent: 'center',
  padding: theme.spacing(0, 0),
  backgroundColor: theme.palette.primary[background]
}));

const IconWrapperStyle = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  alignSelf: 'center'
  //   alignContent: 'center'
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function DemoCard({ color, icon, title, description, grid }) {
  const [isHovered, setHovered] = useState(false);

  return (
    <RootStyle
      background={color}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Typography variant="h4" color="grey.600" sx={{ alignSelf: 'flex-end', marginTop: '16px' }}>
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="grey.600"
        sx={{
          display: 'grid',
          textAlign: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          justifySelf: 'center',
          width: '100%',
          paddingRight: '24px',
          paddingLeft: '24px',
          marginBottom: '24px'
        }}
      >
        {description}
      </Typography>
      <motion.div animate={{ y: isHovered ? -100 : 0 }}>
        <IconWrapperStyle>
          <img
            src={`static/${icon}`}
            alt="login"
            width="100%"
            height="100%"
            style={{
              alignSelf: 'center',
              overflow: 'clip',
              paddingRight: isMobile || grid === 3 ? '64px' : '128px',
              paddingLeft: isMobile || grid === 3 ? '64px' : '128px'
            }}
          />
        </IconWrapperStyle>
      </motion.div>
    </RootStyle>
  );
}
