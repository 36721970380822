// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography, Button, Link } from '@mui/material';
// layouts
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';
import { RegisterForm } from '../components/authentication/register';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const [form, setForm] = useState('login');
  return (
    <RootStyle title="Social Passport | Login">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Button
          onClick={() => {
            if (form === 'login') {
              setForm('register');
            } else {
              setForm('login');
            }
          }}
        >
          {form === 'login' ? 'Get started' : 'Login'}
        </Button>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            The future of connecting
          </Typography>
          <img src="/static/illustrations/MainScreen.png" alt="login" width="75%" />
        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to socialize!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
          </Stack>
          {/* <AuthSocial /> */}

          {form === 'login' ? <LoginForm /> : <RegisterForm />}
          {isMobile ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 10
              }}
            >
              <Link
                variant="text"
                sx={{ margin: 0, padding: 0, marginTop: '30px' }}
                onClick={() => {
                  if (form === 'login') {
                    setForm('register');
                  } else {
                    setForm('login');
                  }
                }}
              >
                {form === 'login' ? 'create new account?' : 'login'}
              </Link>
            </div>
          ) : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
