import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import reducer from './reducers/reducer';

const store = configureStore({
  reducer: {
    reducer
  },
  middleware: [thunk]
});
export const persistor = persistStore(store);

export default store;
