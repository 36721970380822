import { Icon } from '@iconify/react';
import peopleFill from '@iconify/icons-eva/people-outline';
import profileFill from '@iconify/icons-eva/person-outline';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-outline';
import pieChartFill from '@iconify/icons-eva/pie-chart-outline';
import linkFill from '@iconify/icons-eva/edit-2-outline';
import gearFill from '@iconify/icons-eva/settings-outline';
import messageOutline from '@iconify/icons-eva/message-square-outline';
import searchOutline from '@iconify/icons-eva/search-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon(pieChart2Fill)
  // },
  {
    title: 'profile',
    path: '/dashboard/profile',
    icon: getIcon(profileFill)
  },
  {
    title: 'Friends',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  },
  // {
  //   title: 'Search',
  //   path: '/dashboard/search',
  //   icon: getIcon(searchOutline)
  // },
  {
    title: 'Messages',
    path: '/dashboard/messages',
    icon: getIcon(messageOutline)
  },
  {
    title: 'edit profile',
    path: '/dashboard/links',
    icon: getIcon(linkFill)
  },
  {
    title: 'stats',
    path: '/dashboard/stats',
    icon: getIcon(pieChartFill)
  },
  {
    title: 'shop',
    path: '/dashboard/products',
    icon: getIcon(shoppingBagFill)
  },
  {
    title: 'settings',
    path: '/dashboard/settings',
    icon: getIcon(gearFill)
  }
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon(alertTriangleFill)
  // }
];

export default sidebarConfig;
