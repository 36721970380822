import React, { useEffect, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
// import { loadStripe } from '@stripe/stripe-js';
import Page from '../components/Page';
import CheckoutList from '../components/_dashboard/checkout/CheckoutList';
import { selectUser } from '../store/reducers/reducer';
import { db } from '../firebase';
import { fCurrency, fStripe } from '../utils/formatNumber';

// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Checkout() {
  const { uid } = useSelector(selectUser);
  const [itemsBought, setItemsBought] = useState([]);

  useEffect(() => {
    db.collection('users')
      .doc(uid)
      .collection('payments')
      .onSnapshot((snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setItemsBought(items);
      });
  }, [uid]);

  const convertItemsToProducts = () => {
    const products = [];
    // eslint-disable-next-line no-lone-blocks
    {
      itemsBought.map((item) =>
        products.push({
          id: item.id,
          name: item.items[0].description,
          price: fCurrency(fStripe(item.items[0].amount_total)),
          status: 'bought',
          time: item.charges.data[0].created
        })
      );
    }
    return products;
  };

  // const createCheckout = async () => {
  //   console.log(process.env);
  //   // const stripe = await stripePromise;
  //   // call the backend to create a checkout session
  // };

  return (
    <Page title="Social Passport | Products">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Bought products
        </Typography>
        <CheckoutList products={convertItemsToProducts()} />
      </Container>
    </Page>
  );
}

export default Checkout;
