import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { isMobile } from 'react-device-detect';
import LinkCard from '../components/mainPage/LinkCard';
import IntroCard from '../components/mainPage/IntroCard';
import BottomCard from '../components/mainPage/BottomCard';
import InfoCard from '../components/mainPage/InfoCard';
import DemoCard from '../components/mainPage/DemoCard';

function MainPage() {
  return (
    <Container sx={{ marginTop: '16px' }}>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={8}>
          <IntroCard
            title="Connect with a single tap!"
            description="Share your digital profile with your fellow students instantaneously. one tap and you
              are connected."
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            color="pink"
            icon="FasterTime1.png"
            text="Connect using your social passport tag"
            buttonAction="products"
            buttonText="Get passport!"
            animation="wiggle"
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="lightBlue"
            icon="HowToOne.png"
            title="Step #1"
            description="Create your social passport account"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="blue"
            icon="HowToTwo.png"
            title="Step #2"
            description="Add, edit, and select which links you want to display"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="yellow"
            icon="HowToThree.png"
            title="Step #3"
            description="buy a physical passport to instantly share your profile"
          />
        </Grid> */}
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="pink"
            icon="HowToThree.png"
            title="Step #3"
            description="Buy a physical passport to share your link"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="orange"
            icon="HowToSix.png"
            title="Step #4"
            description="Watch your stats grow as you socialize!"
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6}>
          <DemoCard
            color="purple"
            icon="HowToSix.png"
            title="Step #6"
            description="get detailed stats on how you socialize at college"
          />
        </Grid> */}
      </Grid>

      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoCard
            color="green"
            icon="ContactMessage.png"
            text="Many more features to supercharge your college connections!!!"
            animation="running"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <LinkCard />
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ marginTop: '0px' }}>
        <Grid item xs={12} sm={6} md={12}>
          <BottomCard color="300" icon="ContactMessage.png" />
        </Grid>
      </Grid>
      <Typography sx={{ height: '50px' }} />
    </Container>
  );
}

export default MainPage;
