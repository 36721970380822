import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    uid: '',
    name: '',
    openPremium: false,
    isLoading: false,
    loaded: false,
    friends: []
  },
  reducers: {
    login: (state, action) => {
      _.assign(state, action.payload);
    },
    premium: (state, action) => {
      _.assign(state, action.payload);
    },
    setFriends: (state, action) => {
      _.assign(state, { friends: action.payload });
    },
    shop: (state, action) => {
      _.assign(state, action.payload);
    },
    currentUser: (state, action) => {
      _.assign(state, { currentUser: action.payload });
    },
    logout: (state) => {
      _.assign(state, null);
    }
  }
});

export const { login, logout, currentUser, shop, premium, setFriends } = userSlice.actions;

// Selectors
export const selectUser = (state) => state.reducer.user;
export const selectUserLoading = (state) => state.reducer.user.isLoading;
export const selectUserLoaded = (state) => state.reducer.user.isLoading;
export const selectFriends = (state) => state.reducer.user.friends;

export const selectCurrentUser = (state) => state.reducer.user.currentUser;
export const selectLinks = (state) => state.reducer.user.links;

export default userSlice.reducer;
