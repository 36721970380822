/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-const-assign */
/* eslint-disable react/jsx-key */
import {
  Container,
  Typography,
  Snackbar,
  Alert,
  Button,
  TextField,
  Input,
  IconButton,
  Box,
  Avatar,
  Divider,
  InputBase,
  Card
} from '@mui/material';
import Spacer from 'react-spacer';
import { Icon } from '@iconify/react';
import backButton from '@iconify/icons-eva/chevron-left-outline';
import { alpha, styled } from '@mui/material/styles';
import { useParams } from 'react-router';

import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
// import ScrollToBottom from 'react-scroll-to-bottom';
import QRCode from 'qrcode.react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import {
//   ForumOutlined,
//   Computer,
//   PsychologyOutlined,
//   RecordVoiceOverOutlined
// } from '@mui/icons-material';
// import { mockImgProfile } from '../utils/mockImages';
// import { isMobile } from 'react-device-detect';
import { isMobile } from 'react-device-detect';
import ScrollToBottom, { useScrollToBottom } from 'react-scroll-to-bottom';
import useId from '@mui/material/utils/useId';
import moment from 'moment';
import firebase from 'firebase';
import AccountPopover from '../layouts/dashboard/AccountPopover';
import WelcomeModal from '../components/modals/WelcomeModal';
import TimelineWidget from '../components/_dashboard/profile/TimelineWidget';
import TimelineModal from '../components/modals/TimelineModal';
import ChangeProfileModal from '../components/ChangeProfileModal';
import { selectUser } from '../store/reducers/reducer';

// components
import Page from '../components/Page';
import MyProductList from '../components/_dashboard/profile/MyProductList';
//
import PRODUCTS from '../_mocks_/profile';
import MyProfileHeader from '../components/_dashboard/profile/MyProfileHeader';
import { db, storage } from '../firebase';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'sticky',
  // alignItems: 'center',
  // alignSelf: 'center',
  width: '100%',
  // flex: 1,
  // height: '100%',
  // left: 0,
  bottom: 0,
  backgroundColor: 'white',
  paddingBottom: '16px'
  // paddingTop: '16px'
}));

const Messages = () => {
  const [url, setUrl] = useState('');
  const [friendUrl, setFriendUrl] = useState('');
  const [text, setText] = useState('');
  const [friendName, setFriendName] = useState('');
  const [date, setDate] = useState();
  const navigate = useNavigate();
  const { id } = useParams();
  const uid = useSelector((state) => state.reducer.firebase.auth.uid);
  const userName = useSelector((state) => state.reducer.firebase.profile.name);
  const profileImage = useSelector((state) => state.reducer.firebase?.profile?.profileImage);
  const friendProfileImage = useSelector(
    (state) => state.reducer.firestore.data?.users[id]?.profileImage
  );

  const [loading, setLoading] = useState(true);

  const [messages, setMessages] = useState([]);
  const messagesEndRef = useRef(null);

  // const scrollRef = useRef();

  // const scroll = () => {
  //   scrollRef.current.scrollToBottom();
  // };

  const scrollToBottom = () => {
    // messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });

    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
    // window.scrollTo({
    //   top: document.documentElement.scrollHeight,
    //   behavior: 'smooth'
    //   /* you can also use 'auto' behaviour
    //        in place of 'smooth' */
    // });
  };

  useEffect(() => {
    db.collection('users')
      .doc(id)
      .get()
      .then((doc) => {
        setFriendName(doc.data().name);
      });
  }, [id]);

  useEffect(() => {
    if (uid > id) {
      db.collection('messages')
        .doc(uid + id)
        .collection('texts')
        .orderBy('createdAt', 'asc')

        .onSnapshot((snapshot) => {
          const messageData = [];
          snapshot.forEach((doc) => messageData.push({ ...doc.data(), id: doc.id }));
          setMessages(messageData);
        });
    } else if (id > uid) {
      db.collection('messages')
        .doc(id + uid)
        .collection('texts')
        .orderBy('createdAt', 'asc')

        .onSnapshot((snapshot) => {
          const messageData = [];
          snapshot.forEach((doc) => messageData.push({ ...doc.data(), id: doc.id }));
          setMessages(messageData);
        });
    }
  }, [id, uid]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (profileImage) {
      setUrl(profileImage);
    }
  }, [profileImage]);
  useEffect(() => {
    if (friendProfileImage) {
      setFriendUrl(friendProfileImage);
    }
  }, [friendProfileImage]);

  return (
    <Container
      style={{
        flexDirection: 'column',
        flex: 1,
        display: 'flex',
        width: '100%',
        height: messages.length <= 10 ? '100vh' : null,
        marginTop: '0px'
      }}
    >
      <Box
        style={{
          zIndex: 1000,
          height: '80px',
          paddingTop: '16px',
          flexDirection: 'row',
          display: 'flex',
          position: 'sticky',
          top: 0,
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          paddingBottom: '16px',
          backgroundColor: 'white'
        }}
      >
        {/* <Link
          to={`/profile/${id}`}
          style={{
            textDecoration: 'none',
            color: '#000000',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
        > */}
        <IconButton
          style={{ padding: 0, marginRight: '16px' }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icon icon={backButton} width={30} height={30} />
        </IconButton>
        {/* </Link> */}
        <Avatar
          src={friendUrl}
          alt=""
          style={{
            width: 30,
            height: 30,
            borderRadius: 5,
            objectFit: 'cover',
            marginRight: '16px'
          }}
        />
        <Typography
          variant="body"
          gutterBottom
          sx={{
            justifyContent: 'flex-start',
            display: 'flex',
            padding: 0,
            margin: 0,
            flex: 1,
            width: '100%'
          }}
        >
          {friendName}
        </Typography>
        <AccountPopover />
      </Box>

      <div
        style={{
          width: '100%',
          height: '100vh',
          flex: 1,
          overflow: 'scroll'
        }}
      >
        {messages.map((message, index) => {
          let previous = 0;
          if (index !== 0) {
            previous = messages[index - 1].uid;
          }
          return (
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginRight: message.uid === id ? null : null,
                alignItems: 'center',
                alignContent: 'center',
                marginBottom: '8px'
              }}
            >
              {message.uid === id && previous !== message.uid ? (
                <Avatar
                  src={friendUrl}
                  alt=""
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    objectFit: 'cover',
                    marginRight: '16px'
                  }}
                />
              ) : (
                <Box style={{ backgroundColor: 'white', marginRight: '46px' }} />
              )}
              {/* <div> */}
              <Box
                style={{
                  justifyContent: message.uid === id ? 'flex-start' : 'flex-end',
                  display: 'flex',
                  flex: 1,
                  alignItems: 'flex-end'
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    padding: '8px',
                    // backgroundColor: message.uid === 2 ? '#F4F6F8' : '#DAFEFC',
                    backgroundImage:
                      message.uid === id
                        ? null
                        : `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
                            '#FBDA61',
                            0.5
                          )} 100%)`,
                    backgroundColor: message.uid === id ? '#F4F6F8' : 'white',
                    borderRadius: '8px',
                    maxWidth: '100%',
                    justifyContent: message.uid === id ? 'flex-start' : 'flex-end'
                  }}
                >
                  {message.message}
                </Typography>
              </Box>
              {/* </div> */}
              <Typography style={{ fontSize: 11, marginLeft: '8px' }}>
                {moment(message.createdAt).format('LT')}
              </Typography>

              {/* {message.uid === uid && previous !== message.uid ? (
                <Avatar
                  src={url}
                  alt=""
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 15,
                    objectFit: 'cover',
                    marginLeft: '16px'
                  }}
                />
              ) : (
                <Box style={{ backgroundColor: 'white', marginLeft: '46px' }} />
              )} */}
            </div>
          );
        })}
      </div>
      <div ref={messagesEndRef} />

      <RootStyle>
        <Card
          style={{ width: '100%', display: 'flex', flex: 1, padding: '8px', borderRadius: '5px' }}
        >
          <form
            style={{ flexDirection: 'row', display: 'flex', width: '100%' }}
            onSubmit={(e) => {
              e.preventDefault();
              setMessages([...messages, { message: text, uid }]);
              if (uid < id) {
                db.collection('messages')
                  .doc(id + uid)
                  .set(
                    {
                      lastMessage: text,
                      name: friendName,
                      users: [uid, id],
                      lastCreatedAt: new Date(),

                      [uid]: userName,
                      [id]: friendName
                    },
                    { merge: true }
                  );
                db.collection('messages')
                  .doc(id + uid)
                  .collection('texts')
                  .add({ message: text, uid, createdAt: new Date().toISOString(), read: false })
                  .then((doc) => {
                    db.collection('users')
                      .doc(id)
                      .set(
                        {
                          notifyMessage: firebase.firestore.FieldValue.increment(1)
                        },
                        { merge: true }
                      )
                      .then(() => {});
                  });
              } else if (uid > id) {
                db.collection('messages')
                  .doc(uid + id)
                  .set(
                    {
                      lastMessage: text,
                      name: friendName,
                      lastCreatedAt: new Date(),

                      users: [uid, id],
                      [uid]: userName,
                      [id]: friendName
                    },
                    { merge: true }
                  );
                db.collection('messages')
                  .doc(uid + id)
                  .collection('texts')
                  .add({ message: text, uid, createdAt: new Date().toISOString(), read: false })
                  .then((doc) => {
                    db.collection('users')
                      .doc(id)
                      .set(
                        {
                          notifyMessage: firebase.firestore.FieldValue.increment(1)
                        },
                        { merge: true }
                      )
                      .then(() => {});
                  });
              }

              setText('');
            }}
          >
            <InputBase
              size="small"
              placeholder="Type a message"
              style={{ width: '100%', marginRight: '16px', paddingLeft: '8px' }}
              onChange={(event) => {
                setText(event.target.value);
              }}
              value={text}
            />

            <Button
              variant="outlined"
              style={{
                color: 'gray',
                backgroundColor: 'white'
              }}
              onClick={() => {
                setMessages([...messages, { message: text, uid }]);
                if (uid < id) {
                  db.collection('messages')
                    .doc(id + uid)
                    .set(
                      {
                        lastMessage: text,
                        name: friendName,
                        lastCreatedAt: new Date(),
                        users: [uid, id],
                        [uid]: userName,
                        [id]: friendName
                      },
                      { merge: true }
                    );
                  db.collection('messages')
                    .doc(id + uid)
                    .collection('texts')
                    .add({ message: text, uid, createdAt: new Date().toISOString(), read: false })
                    .then((doc) => {
                      db.collection('users')
                        .doc(id)
                        .set(
                          {
                            notifyMessage: firebase.firestore.FieldValue.increment(1)
                          },
                          { merge: true }
                        );
                    });
                } else if (uid > id) {
                  db.collection('messages')
                    .doc(uid + id)
                    .set(
                      {
                        lastMessage: text,
                        lastCreatedAt: new Date(),
                        name: friendName,
                        users: [uid, id],
                        [uid]: userName,
                        [id]: friendName
                      },
                      { merge: true }
                    );
                  db.collection('messages')
                    .doc(uid + id)
                    .collection('texts')
                    .add({ message: text, uid, createdAt: new Date().toISOString(), read: false })
                    .then((doc) => {
                      db.collection('users')
                        .doc(id)
                        .set(
                          {
                            notifyMessage: firebase.firestore.FieldValue.increment(1)
                          },
                          { merge: true }
                        );
                    });
                }

                setText('');
              }}
            >
              Send
            </Button>
          </form>
        </Card>
      </RootStyle>
    </Container>
  );
};

export default Messages;
