import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgProfile } from '../utils/mockImages';

// ----------------------------------------------------------------------

const data = [
  { name: 'Instagram', imagename: 'Instagram.png' },
  { name: 'Call', imagename: 'Call.png' },
  // { name: 'ContactInfo', imagename: 'Contact.png' },
  { name: 'iMessage', imagename: 'iMessage.png' },
  // { name: 'Facebook', imagename: 'Facebook.png' },
  // { name: 'Pinterest', imagename: 'Pinterest.png' },
  { name: 'Safari', imagename: 'Safari.png' },
  { name: 'Email', imagename: 'Email.png' },
  // { name: 'Snapchat', imagename: 'Snapchat.png' },
  // { name: 'Spotify', imagename: 'Spotify.png' },
  // { name: 'Soundcloud', imagename: 'Soundcloud.png' },
  // { name: 'Stanford', imagename: 'Stanford.png' },
  // { name: 'TikTok', imagename: 'TikTok.png' }
  // { name: 'Twitter', imagename: 'Twitter.png' },
  { name: 'Venmo', imagename: 'Venmo.png' },
  { name: 'WhatsApp', imagename: 'WhatsApp.png' }
  // { name: 'Youtube', imagename: 'Youtube.png' }
];

const users = [...Array(data.length)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: mockImgProfile(data[index].imagename),
  name: data[index].name,
  status: sample(['active', 'banned'])
}));

export default users;
