// import { useFormik } from 'formik';
// import { useState } from 'react';
// material
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { db } from '../firebase';
import CheckoutList from '../components/_dashboard/checkout/CheckoutList';
import { ProductList } from '../components/_dashboard/products';
//
import PRODUCTS from '../_mocks_/products';
import { fCurrency, fStripe } from '../utils/formatNumber';
import { selectUser } from '../store/reducers/reducer';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const { uid } = useSelector(selectUser);
  const [itemsBought, setItemsBought] = useState([]);

  useEffect(() => {
    db.collection('users')
      .doc(uid)
      .collection('payments')
      .onSnapshot((snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          items.push({ ...doc.data(), id: doc.id });
        });
        setItemsBought(items);
      });
  }, [uid]);

  const convertItemsToProducts = () => {
    const products = [];
    // eslint-disable-next-line no-lone-blocks
    {
      itemsBought.map((item) =>
        products.push({
          id: item.id,
          name: item.items[0].description,
          price: fCurrency(fStripe(item.items[0].amount_total)),
          status: 'bought',
          time: item.charges.data[0].created
        })
      );
    }
    return products;
  };

  return (
    <Page title="Social Passport | Products">
      <Container>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Shop
        </Typography>
        <ProductList products={PRODUCTS} />

        {/* {uid ? (
          <>
            <ProductCartWidget numItems={itemsBought.length} />
            <Typography variant="h4" sx={{ my: 5 }}>
              Bought Products
            </Typography>
            <CheckoutList products={convertItemsToProducts()} />
          </>
        ) : null} */}
      </Container>
    </Page>
  );
}
