import { styled } from '@mui/material/styles';
import { Button, Card, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

// utils
// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme, background }) => ({
  display: 'grid',
  height: 350,
  // justifyContent: 'space-between',
  boxShadow: 'none',
  textAlign: 'center',
  // width: '100%',
  flex: 1,
  padding: theme.spacing(4, 4),
  backgroundColor: theme.palette.primary[background]
}));

const IconWrapperStyle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  alignContent: 'center'
}));

// ----------------------------------------------------------------------

// eslint-disable-next-line react/prop-types
export default function InfoCard({ color, icon, text, buttonText, buttonAction, animation }) {
  const [isHovered, setHovered] = useState(false);

  // const variants = {
  //   visible: { opacity: 1 },
  //   hidden: { opacity: 0 }
  // };
  // const x = useMotionValue(0);

  // const controls = animate(x, 100, {
  //   type: 'spring',
  //   stiffness: 2000,
  //   onComplete: (v) => {}
  // });

  const getAnimation = (animation) => {
    switch (animation) {
      case 'wiggle':
        return { rotate: [6, -6, 0] };
      case 'running':
        return { x: isHovered ? [0, 300, -300, 0] : 0, opacity: isHovered ? [1, 0, 0, 1] : 1 };
      default:
        return {};
    }
  };
  const getTransition = (animation) => {
    switch (animation) {
      case 'wiggle':
        return { yoyo: Infinity };
      case 'running':
        return {};
      default:
        return {};
    }
  };

  return (
    <RootStyle
      background={color}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <motion.div
        animate={getAnimation(animation)}
        transition={getTransition(animation)}
        style={{ alignSelf: 'center', width: '100%' }}
      >
        <IconWrapperStyle>
          <img
            src={`static/${icon}`}
            alt="login"
            width={150}
            height={150}
            style={{ alignSelf: 'center' }}
          />
        </IconWrapperStyle>
      </motion.div>
      <Typography
        variant="body1"
        color="grey.600"
        sx={{ textAlign: 'center', alignSelf: 'center' }}
      >
        {text}
      </Typography>
      <Link
        to={`/${buttonAction}`}
        style={{
          textDecoration: 'none',
          color: 'text.secondary',
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        {buttonText ? (
          <Button variant="contained" style={{ marginTop: '16px' }}>
            {buttonText}
          </Button>
        ) : null}
      </Link>
    </RootStyle>
  );
}
