import _, { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { useTheme, styled, alpha } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`
  }
}));

// ----------------------------------------------------------------------

export default function LinkPieChart() {
  const theme = useTheme();
  const [stats, setStats] = useState();
  const profile = useSelector((state) => state.reducer.firebase.profile);
  const totalLinks = _.omit(stats, 'visits');
  const links = Object.keys(totalLinks);
  const CHART_DATA = Object.values(totalLinks);

  useEffect(() => {
    if (profile) {
      setStats(profile.stats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.main,
      theme.palette.info.main,
      theme.palette.warning.main,
      theme.palette.error.main
    ],
    labels: links,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `#${seriesName}`
        }
      }
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } }
    }
  });

  return (
    <Card
    // style={{
    //   backgroundImage: `linear-gradient(135deg, ${alpha('#FF5ACD', 0.5)} 0%, ${alpha(
    //     '#FBDA61',
    //     0.5
    //   )} 100%)`
    // }}
    >
      <CardHeader title="Links pie chart" />
      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={CHART_DATA} options={chartOptions} height={280} />
      </ChartWrapperStyle>
    </Card>
  );
}
